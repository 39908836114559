import React, { useState } from 'react';
import { useMutation } from 'react-query';
import axios from 'axios';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  Typography,
  TextField,
  Box,
} from '@mui/material';

const MuiExcelUploadDialog = ({ open, onClose }) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [isOption, setIsOption] = useState('visitorfile');

  // Define mutation to handle file upload
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (formData) => {
      // Replace with your actual upload API URL
      return axios.post('https://api.2kvirtualworld.com/DADEV/casefile/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    {
      onSuccess: () => {
        // Optionally close the dialog on success
        onClose();
      },
    }
  );

  // Handle file input change
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.name.endsWith('.xlsx')) {
      setFile(selectedFile);
      setError('');
    } else {
      setFile(null);
      setError('Please select an Excel file (.xlsx)');
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileType', isOption);
      mutate(formData);
    } else {
      setError('No file selected');
    }
  };
  console.log("isOption", isOption)
  return (
    <Dialog open={open} onClose={() => onClose(false)} maxWidth="xs" fullWidth>
      <div style={{ display: "flex", justifyContent: "space-between" }}>

        <DialogTitle>Upload {isOption === "visitorfile" ? "Visitor" : "Client"} Excel File</DialogTitle>
        <DialogTitle>
          <select onChange={(e) => setIsOption(e.target.value)}>
            <option value="visitorfile">Visitor</option>
            <option value="casefile">Client</option>
          </select>
        </DialogTitle>
      </div>
      <DialogContent>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <TextField
            type="file"
            inputProps={{ accept: '.xlsx' }}
            onChange={handleFileChange}
            error={Boolean(error)}
            helperText={error}
          />
          {isError && (
            <Typography color="error">
              Upload failed. Please try again.
            </Typography>
          )}
          {isSuccess && (
            <Typography color="primary">File uploaded successfully!</Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={isLoading || !file}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Submit'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MuiExcelUploadDialog;
