import React, { useEffect } from 'react'
import VisitorInformationCom from '../component/VisitorInformationCom'

export default function VisitorInformation() {
  // useEffect(()=>{
  //   window.scrollTo({
  //     top: document.body.scrollHeight, // Scroll to the bottom
  //     behavior: 'smooth', // Smooth scrolling
  //   });
  // },[])
  return (
    <div className='mainContainerDiv'>
    <VisitorInformationCom />
    </div>
  )
}
