import React, { useState, useEffect } from "react";
import wrongIcon from "../asset/close.svg";

function Modal({
  show,
  onClose,
  onSave,
  currentField,
  value,
  showAddModalOpen,
}) {
  const [inputValue, setInputValue] = useState(value);
  // console.log("modal", showAddModalOpen);

  useEffect(() => {
    setInputValue(value); // Update input value when modal opens or value changes
  }, [value]);

  if (!show) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 100,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onClose}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          width: "70%",
          // height: "70vh",
          height: "auto",
          overflow: "scroll",
          scrollbarWidth: "none",
          borderRadius: "5px",
          position: "relative",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div style={{ position: "absolute", right: "18px", top: "10px" }}>
          <img
            onClick={onClose}
            src={wrongIcon}
            style={{
              backgroundColor: "black",
              height: "30px",
              width: "33px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            alt="no-image"
          />
        </div>

        <h6 className="py-2">{currentField?.labelName}</h6>
        <textarea
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          style={{ height: "50vh", width: "100%" }}
          className="visitorAddCss py-2"
        />
        <div className="d-flex justify-content-lg-between align-items-center gap-2 pt-1">
          <button
            // onClick={onClose} // Close modal without saving
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              setInputValue("");
            }}
          >
            Delete
          </button>
          <button
            onClick={onClose} // Save the input value to form state
            type="button"
            className="btn btn-primary"
          >
            Back
          </button>

          <button
            onClick={() => onSave(inputValue)} // Save the input value to form state
            type="button"
            className="btn btn-primary"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
