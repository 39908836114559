// // import { TableCell } from '@mui/material';
// // import React from 'react';
// import React, { useState } from "react";
// import Images from "../asset";
// import { useQuery } from "react-query";
// import axios from "axios";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
// } from "@mui/material";
// import moment from "moment";
// import { Navigate, useNavigate } from "react-router";
// // import Loading from "./Loading";

// export default function ViewRegister() {
//   const [searchTerm, setSearchTerm] = useState();
//   const [ageTerm, setAgeTerm] = useState();
//   const [selectValue, setSelectValue] = useState();
//   const navigator = useNavigate();
//   const ageFilter = ageTerm?.split("/");
//   console.log("words", ageTerm);

//   const fetchingData = async () => {
//     return await axios.get(`https://api.2kvirtualworld.com/DADEV/registers`);
//   };
//   const { data, isLoading, isError, refetch } = useQuery(
//     "fetchingData_VisitorViewTable",
//     fetchingData
//   );
// //   const dataDetails = data && data?.data;
//   const dataDetails = data && data?.data;
//   console.log("dataDetails", dataDetails);
// //   let actionFilterValue = [];
//   // actionFilterValue = data?.data?.filter((item) => {
//   //   // console.log("ccccc", item && item?.visitorInfoList[0]);
//   //   if (searchTerm) {
//   //     const nameMatches = `${
//   //       item?.visitorInfoList[0]?.[selectValue] ||
//   //       item?.visitorInfoList[0]?.name
//   //     }`
//   //       ?.toLowerCase()
//   //       .startsWith(searchTerm?.toLowerCase());
//   //     // const placeMatches = item?.visitorInfoList[0]?.placeOfResidence
//   //     //   ?.toLowerCase()
//   //     //   .startsWith(searchTerm?.toLowerCase());
//   //     // const mobileMatches = item?.visitorInfoList[0]?.age
//   //     //   ?.toString()
//   //     //   .startsWith(searchTerm);

//   //     return nameMatches;
//   //   } else if (ageTerm) {
//   //     const age = item?.visitorInfoList[0]?.age;
//   //     console.log("age", age);
//   //     if (
//   //       age !== undefined &&
//   //       ageFilter[0] !== undefined &&
//   //       ageFilter[1] !== undefined
//   //     ) {
//   //       return age >= ageFilter[0] && age <= ageFilter[1]; // Returns only item within age range
//   //     }
//   //   } else {
//   //     return dataDetails;
//   //   }
//   // });
//   return (
//     <>
//       <div className="headerTimeLine px-3 py-3">
//         <div className="px-5 d-flex" style={{ justifyContent: "end" }}>
//           {/* <div
//           className="d-flex search-block"
//           style={{
//             justifyContent: "space-between",
//             alignItems: "center",
//             padding: "10px 10px",
//           }}
//         >
//           <input
//             type="text"
//             className="table-search"
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}
//             placeholder="Search"
//           />
//           <div className="image_icon">
//             <img
//               src={Images.searchIcon}
//               style={{ width: "100%", height: "100%" }}
//               alt="Search"
//             />
//           </div>
//         </div> */}
//           {/* <div>
//             <select
//               className="d-flex search-block"
//               style={{
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 padding: "10px 10px",
//                 height: "50px",
//                 marginRight: "10px",
//               }}
//               onChange={(event) => {
//                 setSelectValue(event.target.value);
//               }}
//             >
//               <option value={" "}> Search Type </option>
//               <option value="name">Name</option>
//               <option value="mobileNumber">Mobile Number</option>
//               <option value="category">Category</option>
//               <option value="age">Age</option>
//               <option value="placeOfResidence">Place</option>
//               <option value="religion">Religion</option>
//               <option value="caste">Caste</option>
//             </select>
//           </div>
//           {selectValue === "age" ? (
//             <div
//               className="d-flex search-block"
//               style={{
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 padding: "10px 10px",
//               }}
//             >
//               <input
//                 type="text"
//                 className="table-search"
//                 value={searchTerm}
//                 onChange={(e) => setAgeTerm(e.target.value)}
//                 placeholder="Search Age From / To"
//               />
//               <div className="image_icon">
//                 <img
//                   src={Images.searchIcon}
//                   style={{ width: "100%", height: "100%" }}
//                   alt="Search"
//                 />
//               </div>
//             </div>
//           ) : (
//             <div
//               className="d-flex search-block"
//               style={{
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 padding: "10px 10px",
//               }}
//             >
//               <input
//                 type="text"
//                 className="table-search"
//                 value={searchTerm}
//                 onChange={(e) => setSearchTerm(e.target.value)}
//                 placeholder="Search"
//               />
//               <div className="image_icon">
//                 <img
//                   src={Images.searchIcon}
//                   style={{ width: "100%", height: "100%" }}
//                   alt="Search"
//                 />
//               </div>
//             </div>
//           )}
//         </div> */}
//         </div>
//       </div>
//   <div className="pe-3">
//     <TableContainer className="product_table_container_sticky">
//       <Table>
//         <TableHead className="product_heading_sticky ">
//           <TableRow>
//             {/* <TableCell className="product_table_heading">
//               Select
//             </TableCell> */}
//             <TableCell className="product_table_heading">User ID</TableCell>
//             <TableCell className="product_table_heading">
//               User Name
//             </TableCell>

//             <TableCell className="product_table_heading">E-mail</TableCell>

//             <TableCell className="product_table_heading">Edit</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {isLoading ? (
//             <p>...Loading</p>
//           ) : dataDetails ? (
//             dataDetails &&
//             dataDetails?.map((item, i) => {
//               {/* console.log("clientinformation", item); */}
//               return (
//                 <TableRow key={i}>
//                   <TableCell className="viewRegister_table_data">
//                     {item && item?.id}
//                   </TableCell>
//                   <TableCell className="viewRegister_table_data">
//                     {item && item?.userName}
//                   </TableCell>

//                   <TableCell className="viewRegister_table_data">
//                     {item && item?.email}
//                   </TableCell>

//                   <TableCell
//                     className="viewRegister_table_data "
//                     onClick={() => {

//                         navigator("/userRegister",{state:item})
//                     }}

//                     >
//                       <img
//                         src={Images?.pencilIcon}
//                         alt="edit-icon"
//                         style={{ width: "20%", height: "100%" }}
//                       />
//                     {/* </div> */}
//                   </TableCell>
//                 </TableRow>
//               );
//             })
//           ) : (
//             <>
//               <p
//                 style={{
//                   width: "300%",
//                   color: "#002147",
//                   fontSize: "18px",
//                   fontWeight: "500",
//                 }}
//               >
//                 No Records Found
//               </p>
//             </>
//           )}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   </div>
//     </>
//   );
// }
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import Images from "../asset";
import { useNavigate } from "react-router";
import Alert from "../component/Alert";
// import Lo

export default function ViewRegister() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [dltId, setDltId] = useState();
  // const [postAlert, setPostAlert] = useState();
  const [putAlert, setPutAlert] = useState();
  const [postAlert, setPostAlert] = useState();
  const fetchingData = async () => {
    return await axios.get(`https://api.2kvirtualworld.com/DADEV/registers`);
  };
  const { data, isLoading, isError, refetch } = useQuery(
    "fetchingData_ViewRegisterTable",
    fetchingData
  );
  const dataValue = data && data?.data;

  // Filter data based on search term
  const filteredData =
    dataValue &&
    dataValue?.filter((item) => {
      if (!searchTerm) return true; // Show all data if searchTerm is empty
      const search = searchTerm?.toLowerCase();
      return (
        item.userName?.toLowerCase().startsWith(search) || // Match startsWith for userName
        item.email?.toLowerCase().startsWith(search) || // Match startsWith for email
        item.id?.toString().startsWith(search)
      );
    });
  const deleteUser = async (id) => {
    return await axios.delete(
      `https://api.2kvirtualworld.com/DADEV/registers/${id}`
    );
  };

  // // Delete multiple users
  const deleteUsers = async (ids) => {
    return await Promise.all(
      ids?.map((id) => {
        return deleteUser(id);
      })
    );
  };
  let details = (data && data?.data) || [];
  // console.log("details", details);

  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows?.includes(id);
      if (isSelected) {
        return prevSelectedRows?.filter((rowId) => rowId !== id);
      } else {
        return [...prevSelectedRows, id];
      }
    });
  };

  const mutation = useMutation({
    mutationFn: deleteUsers,
    onSuccess: () => {
      refetch();

      setPutAlert(false);
      setSelectedRows([]); // Clear selected rows
      // window.location.reload()
    },
    onError: (error) => {
      // console.error("Error deleting rows:", error);
      alert("Error deleting rows");
    },
  });
  const handleDelete = (id) => {
    setDltId(id);
    setPostAlert(true);
  };
  useEffect(() => {
    if (selectedRows?.length !== 0 && putAlert) {
      mutation.mutate(selectedRows);
      setPostAlert(false);
      setPutAlert(false)
    } else if (putAlert && dltId) {
      deleteUser(dltId)
        .then(() => {
          refetch();
          setPostAlert(false);
          setPutAlert(false)
          setDltId(null);
        })
        .catch((error) => {
          alert("Error deleting row");
        });
    }
  }, [selectedRows, putAlert, dltId]);

  return (
    <section className="clientViewMainSection p-0 m-0">
      <div className="container-fluid">
        <div className="row p-0 m-0">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-4 pt-3 pb-2 d-flex align-items-center gap-1">
                <div
                  style={{ width: "50px", height: "40px", cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                >
                  <img
                    src={Images?.arrowBack}
                    style={{ width: "100%", height: "100%" }}
                    alt="back"
                    title="back arrow"
                  />
                </div>
                <h6 className="client-view-head pt-2">Register Entry</h6>
              </div>
              <div className="col-8 d-flex align-items-center justify-content-end gap-2">
                <div className="search-block-client-view">
                  <input
                    type="search"
                    className="table-search"
                    value={searchTerm}
                    placeholder="Search by userId, name,  emailId"
                    title="Search by userId, name, emailId"
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <div className="image_icon">
                    <img
                      src={Images.searchIcon}
                      style={{ width: "100%", height: "100%" }}
                      alt="Search"
                    />
                  </div>
                </div>
                <button
                  className="new-register-btn --client-register"
                  onClick={() => {
                    navigate("/userRegister");
                  }}
                >
                  NEW REGISTER
                </button>
              </div>
            </div>
            <div className="register-table-block">
              <TableContainer className="product_table_container_sticky">
                <Table>
                  <TableHead className="product_heading_sticky">
                    <TableRow>
                      <TableCell className="product_table_heading">
                        User ID
                      </TableCell>
                      <TableCell className="product_table_heading">
                        User Name
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Mobile Number
                      </TableCell>

                      <TableCell className="product_table_heading">
                        E-mail
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Role
                      </TableCell>

                      <TableCell className="product_table_heading">
                        Edit
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <p>...Loading</p>
                    ) : filteredData ? (
                      filteredData &&
                      filteredData?.map((item, i) => {
                        return (
                          <>
                            <TableRow key={i}>
                              <TableCell className="viewRegister_table_data">
                                {item && item?.id}
                              </TableCell>
                              <TableCell className="viewRegister_table_data">
                                {item && item?.userName}
                              </TableCell>
                              <TableCell className="viewRegister_table_data">
                                {item && item?.mobileNumber}
                              </TableCell>

                              <TableCell className="viewRegister_table_data">
                                {item && item?.email}
                              </TableCell>
                              <TableCell className="viewRegister_table_data">
                                {item && item?.role === "superAdmin"
                                  ? "Admin"
                                  : "user"}
                              </TableCell>

                              <TableCell className="viewRegister_table_data ">
                                <img
                                  onClick={() => {
                                    navigate("/userRegister", { state: item });
                                  }}
                                  src={Images?.pencilIcon}
                                  title="Edit Row"
                                  alt="edit-icon"
                                  style={{
                                    width: "30px",
                                    height: "25px",
                                    cursor: "pointer",
                                  }}
                                />

                                <img
                                  src={Images?.deleteIcon}
                                  alt="edit-icon"
                                  title="Delete Row"
                                  style={{ width: "30%", height: "100%" }}
                                  onClick={() => handleDelete(item?.id)}
                                />
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })
                    ) : (
                      <>
                        <TableRow>
                          <TableCell colSpan={5}>No data available</TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
      <Alert
        open={postAlert}
        setPutAlert={setPutAlert}
        // postAlert={postAlert}
        // putAlert={putAlert}
        add={false}
        onCloses={setPostAlert}
        // setPutAlert={setPutAlert}
      />
    </section>
  );
}
