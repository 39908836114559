import React, { useMemo, useState } from "react";
import Images from "../asset";
import { useNavigate } from "react-router";
import ReactPaginate from "react-paginate";
import { useTable } from "react-table";
import MuiExcelUploadDialog from "../component/FileUploadModel";
import * as XLSX from "xlsx";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import Loading from "../component/Loading";
import axios from "axios";
import { useQuery } from "react-query";
import Excel from "../asset/VisitorDemoFile.xlsx";
import ClientDetails from "../asset/clientDetails.xlsx";
import Dropdown from "react-multilevel-dropdown";
export default function FileUpload() {
  const navigate = useNavigate();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusValue, setStatusValue] = useState();
  const [currentPages, setCurrentPages] = useState(0);
  const [isModel, setIsModel] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState("visitor");
  const [sortOrder, setSortOrder] = useState(null);
  const [dobSortOrder, setDobSortOrder] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]); // Store selected items
  const [selectedValues, setSelectedValues] = useState({}); // Store selected values for each category

  const handleCheckboxChange = (type) => {
    setSelectedDetail((prev) => (prev === type ? "" : type));
  };

  const fetchingData = async () => {
    return await axios.get(
      `https://api.2kvirtualworld.com/DADEV/visitorsfile/all`
    );
  };

  const clientData = async () => {
    return await axios.get(`https://api.2kvirtualworld.com/DADEV/visitors/all`);
  };
  const { data: clientdetails } = useQuery("clientData", clientData);
  const { data, isLoading, isError, refetch } = useQuery(
    "fetchingData",
    fetchingData
  );

  const getFileUploadData = data && data?.data;
  let details = (data && data?.data) || [];
  let clientInformation = (clientdetails && clientdetails?.data) || [];

  const visitorfileData = async () => {
    return await axios.get(
      `https://api.2kvirtualworld.com/DADEV/casefile/visitorfile`
    );
  };
  const { data: visitorData } = useQuery("visitorfileData", visitorfileData);

  let visitorFileDetails = visitorData && visitorData?.data;
  const visitorFilterData =
    visitorFileDetails &&
    visitorFileDetails?.filter((row) =>
      Object?.keys(row).some(
        (key) => key !== "id" && String(row[key])?.trim() !== ""
      )
    );
  console.log("visitorFilterData", visitorFilterData);

  const casefileData = async () => {
    return await axios.get(
      `https://api.2kvirtualworld.com/DADEV/casefile/casefile`
    );
  };
  const { data: caseData } = useQuery("casefileData", casefileData);

  let caseFileDetails = caseData && caseData?.data;
  const clientFilterData =
  caseFileDetails &&
  caseFileDetails?.filter((row) =>
      Object?.keys(row).some(
        (key) => key !== "id" && String(row[key])?.trim() !== ""
      )
    );

  const splitCategory = visitorFilterData
    ?.map((item) => item?.religionCaste?.split("/"))
    .filter(Boolean) // Remove null or undefined
    .map(JSON.stringify) // Convert to string for deduplication
    .filter((value, index, self) => self.indexOf(value) === index) // Remove duplicates
    .map(JSON.parse) // Convert back to arrays
    .map(([religion, caste]) => ({ religion, caste })); // Convert arrays to objects

  console.log("splitCategory", splitCategory);

  console.log("split", splitCategory);
  const splitReligionValue = Array.from(
    new Set(splitCategory?.map((item) => item?.religion))
  ).filter(Boolean);
  const splitCastValue = Array.from(
    new Set(splitCategory?.map((item) => item?.caste))
  ).filter(Boolean);

  const categories = [
    // { value: "name", label: "Name" },
    { value: "age", label: "Age" },
    { value: "Caste", label: "Caste" },
    // { value: "place", label: "Place" },
    { value: "religionCaste", label: "Religion" },
    { value: "category", label: "Category" },
    // { value: "dob", label: "Date Of Birth" },
  ];

  const generateAgeOptions = () => {
    const ageRanges = [
      "0/18",
      "18/30",
      "30/40",
      "40/50",
      "50/60",
      "60/70",
      "70/80",
      "80/90",
      "90/99",
    ];

    return (
      ageRanges &&
      ageRanges?.map((range) => ({
        label: `${range}`,
        value: range,
      }))
    );
  };
  const generaterelegionOptions = () => {
    const religionRanges = splitReligionValue;

    return (
      religionRanges &&
      religionRanges?.map((range) => ({
        label: `${range}`,
        value: range,
      }))
    );
  };
  const generateCastOptions = () => {
    const casteRanges = splitCastValue;

    return (
      casteRanges &&
      casteRanges?.map((range) => ({
        label: `${range}`,
        value: range,
      }))
    );
  };

  const generateOptions = (category) => {
    if (!category) return [];

    if (category === "age") {
      return generateAgeOptions();
    }
    if (category === "religionCaste") {
      return generaterelegionOptions();
    }
    if (category === "Caste") {
      return generateCastOptions();
    }

    const uniqueValues = [
      ...new Set(
        filteredVisitors && filteredVisitors?.map((item) => item[category])
      ),
    ];

    return (
      uniqueValues &&
      uniqueValues?.map((value) => ({
        label: value,
        value,
      }))
    );
  };

  const handleItemSelection = (selectedOption) => {
    setSelectedItems((prevItems) => {
      const isAlreadySelected =
        prevItems &&
        prevItems?.some(
          (item) =>
            item?.value === selectedOption?.value &&
            item?.label === selectedOption?.label
        );
      if (isAlreadySelected) {
        return (
          prevItems &&
          prevItems?.filter(
            (item) =>
              item?.value !== selectedOption?.value ||
              item?.label !== selectedOption?.label
          )
        ); // Remove item
      } else {
        return [...prevItems, selectedOption]; // Add item
      }
    });
  };

  const handleCategorySelect = (categoryItem) => {
    console.log("Selected Category:", categoryItem); // Debugging log
  };
  const handleValueSelect = (category, option) => {
    console.log("Selected Value:", option); // Debugging log
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [category]: option?.value, // Update the selected value for the specific category
    }));
    handleItemSelection(option); // Add item to selected items
  };

  const handleRemoveItem = (item) => {
    setSelectedItems(
      (prevItems) =>
        prevItems &&
        prevItems?.filter(
          (i) => i?.value !== item?.value || i?.label !== item?.label
        )
    );

    setSelectedValues((prevValues) => {
      const updatedValues = { ...prevValues };
      const category = Object.keys(prevValues).find(
        (key) => prevValues[key] === item?.label
      );
      if (category) {
        delete updatedValues[category];
      }
      return updatedValues;
    });
  };
  // Visitor search

  const filteredVisitors = searchTerm
    ? visitorFilterData &&
      visitorFilterData?.filter((visitor) => {
        const term = searchTerm?.toLowerCase();
        return (
          // (visitor && visitor?.fileNumber?.toString().startsWith(term)) ||
          (visitor &&
            visitor?.nameAgeGender?.toString().toLowerCase().includes(term)) ||
          (visitor &&
            visitor?.dateOfVisit?.toString().toLowerCase().includes(term)) ||
          (visitor &&
            visitor?.religionCaste?.toString().toLowerCase().includes(term)) ||
          (visitor && visitor?.mobileNo?.toString().startsWith(term)) ||
          (visitor && visitor?.email?.toString().startsWith(term)) ||
          (visitor && visitor?.address?.toLowerCase().includes(term)) ||
          (visitor && visitor?.dob?.toString().toLowerCase().startsWith(term))
        );
      })
    : visitorFilterData;

  // client search

  const filteredClients = searchTerm
    ? clientFilterData &&
    clientFilterData?.filter((client) => {
        const term = searchTerm?.toLowerCase();
        return (
          // (visitor && visitor?.fileNumber?.toString().startsWith(term)) ||
          (client &&
            client?.clientName?.toString().toLowerCase().startsWith(term)) ||
          (client &&
            client?.clientAge?.toString().toLowerCase().startsWith(term)) ||
          (client && client?.clientMobNo?.toString().startsWith(term)) ||
          (client && client?.subjectName?.toString().startsWith(term)) ||
          (client && client?.subjectMobNo?.toString().startsWith(term)) ||
          (client && client?.subjectResAddress?.toString().includes(term)) ||
          (client && client?.caseFileNo?.toString().startsWith(term)) ||
          (client && client?.emailId?.toString().startsWith(term)) ||
          (client && client?.clientAddress?.toLowerCase().includes(term))
        );
      })
    : clientFilterData;

  const filteredDatas =
    filteredVisitors &&
    filteredVisitors?.filter((row) => {
      return Object.keys(selectedValues)?.every((category) => {
        if (selectedValues[category]) {
          // Check if the selected category is "age"
          if (category === "age" && selectedValues[category]) {
            console.log("row", selectedValues, category);
            const ageRange = selectedValues[category]; // Get the selected age range
            const [minAge, maxAge] = ageRange.split("/").map(Number); // Split and convert to numbers
            const age = Number(row[category]); // Get the age from the row data

            // Check if the row's age is within the selected range
            return age >= minAge && age <= maxAge;
          }

          // Default filtering for other categories
          console.log("naem", row[category], selectedValues[category]);

          return row["religionCaste"] === selectedValues[category];
        }
        return true;
      });
    });

  const sortedData = useMemo(() => {
    if (!filteredDatas) return [];

    let sorted = [...filteredDatas]; // Create a shallow copy of the data

    // Combine sorting logic: Apply both name and DOB sorting consistently
    sorted?.sort((a, b) => {
      // Sort by name first if `sortOrder` is defined
      if (sortOrder === "atoz" || sortOrder === "ztoa") {
        const nameA = a.nameAgeGender ? a.nameAgeGender.toLowerCase() : "";
        const nameB = b.nameAgeGender ? b.nameAgeGender.toLowerCase() : "";
        const nameComparison = nameA.localeCompare(nameB);

        if (sortOrder === "atoz") return nameComparison;
        if (sortOrder === "ztoa") return -nameComparison;
      }

      // Then, sort by DOB if `dobSortOrder` is defined
      if (dobSortOrder === "dobAscending" || dobSortOrder === "dobDescending") {
        const dobComparison = new Date(a.dob) - new Date(b.dob);

        if (dobSortOrder === "dobAscending") return dobComparison;
        if (dobSortOrder === "dobDescending") return -dobComparison;
      }

      return 0; // No sorting applied
    });

    return sorted;
  }, [filteredVisitors, sortOrder, dobSortOrder, filteredDatas]);

  console.log("filteredDatas", filteredDatas);
  const clientInfo = useMemo(() => {
    if (!filteredClients) return [];
    const sorted = [...filteredClients];
    if (sortOrder === "atoz") {
      sorted?.sort((a, b) =>
        a?.clientName?.toLowerCase().localeCompare(b?.clientName?.toLowerCase())
      );
    } else if (sortOrder === "ztoa") {
      sorted?.sort((a, b) =>
        b?.clientName?.toLowerCase().localeCompare(a?.clientName?.toLowerCase())
      );
    }
    return sorted;
  }, [filteredClients, sortOrder]);

  const searchData = getFileUploadData?.filter((item, index) => {
    return item?.fileNumber?.toString() === searchTerm;
  });
  // console.log("serchData", searchData);

  return (
    <>
      <div className="headerTimeLine px-3 py-3">
        <div className="d-flex" style={{ justifyContent: "space-between" }}>
          <div className="row pt-4 px-2">
            <div className="col-12 d-flex justify-content-start align-items-center gap-5">
              <label className="d-flex align-items-center gap-2">
                <input
                  type="checkbox"
                  checked={selectedDetail === "visitor"}
                  onChange={() => handleCheckboxChange("visitor")}
                  style={{ width: "20px", height: "20px", cursor: "pointer" }}
                />
                Visitor
              </label>

              <label className="d-flex align-items-center gap-2">
                <input
                  type="checkbox"
                  checked={selectedDetail === "client"}
                  onChange={() => handleCheckboxChange("client")}
                  style={{ width: "20px", height: "20px", cursor: "pointer" }}
                />
                Client
              </label>
            </div>
          </div>
          <div
            className="d-flex gap-2 align-items-center"
            style={{ width: "50%" }}
          >
            <div
              className="add-user-btn-block"
              onClick={() => navigate("/visitorEntry")}
            >
              <button className="new-user-btn">New Visitor</button>
            </div>

            <a
              download="Visitor sample file.xlsx"
              href={Excel}
              className="add-user-btn-block"
              style={{ textDecoration: "none !important" }}
              // onClick={() => {
              //   downloadExcel();
              // }}
            >
              <button
                className="new-user-btn"
                style={{ textDecoration: "none !important" }}
              >
                Download Sample File Visitor
              </button>
            </a>
            <a
              download="Client sample file.xlsx"
              href={ClientDetails}
              className="add-user-btn-block"
              style={{ textDecoration: "none !important" }}
              // onClick={() => {
              //   downloadExcel();
              // }}
            >
              <button
                className="new-user-btn"
                style={{ textDecoration: "none !important" }}
              >
                Download Sample File Client
              </button>
            </a>

            {/* <div className="add-user-btn-block-delete">
              <button
                className="new-user-btn"
                onClick={() => {
                  setPopupOpen(true);
                }}
                // disabled={mutation.isLoading}
              >
                <img
                  src={Images.deleteIcon}
                  alt={"deleteIcon"}
                  style={{ width: "35px", height: "%" }}
                />
              </button>
            </div> */}
          </div>
        </div>
      </div>

      <div className="table_form_page">
        <div className="visitor-main-container px-1 m-0">
          {selectedDetail == "visitor" && (
            <>
              <div className="row  pb-1">
                <div className="col-4  d-flex align-items-center gap-1">
                  <div
                    style={{ width: "50px", height: "40px", cursor: "pointer" }}
                    onClick={() => navigate(-1)}
                  >
                    <img
                      src={Images?.arrowBack}
                      style={{ width: "100%", height: "100%" }}
                      alt="no-image"
                    />
                  </div>
                  <h6 className="client-view-head pt-2">Visitor File Data</h6>
                </div>
                <div className="col-8 d-flex align-items-center justify-content-end gap-2">
                  <div className="search-block-client-view">
                    <input
                      type="search"
                      className="table-search"
                      value={searchTerm}
                      placeholder="Search by  name, age, mobNo, emailID, place"
                      title="Search by  name, age, mobNo, emailID ,place"
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className="image_icon">
                      <img
                        src={Images?.searchIcon}
                        style={{ width: "100%", height: "100%" }}
                        alt="Search"
                      />
                    </div>
                  </div>

                  {/* <Dropdown title="Filter">
                    {categories &&
                      categories?.map((categoryItem) => (
                        <Dropdown.Item
                          key={categoryItem?.value}
                          onClick={() => handleCategorySelect(categoryItem)}
                        >
                          {categoryItem?.label}
                          <Dropdown.Submenu>
                            {generateOptions(categoryItem?.value).map(
                              (option) => (
                                <Dropdown.Item
                                  key={option?.value}
                                  onClick={() =>
                                    handleValueSelect(
                                      categoryItem?.value,
                                      option
                                    )
                                  }
                                >
                                  {option.label}
                                </Dropdown.Item>
                              )
                            )}
                          </Dropdown.Submenu>
                        </Dropdown.Item>
                      ))}
                  </Dropdown> */}
                  <div className="d-flex justify-content-end  me-4">
                    <div style={{ width: "100%" }}>
                      <div
                        className="fileNewUpload"
                        style={{ width: "100%" }}
                        onClick={() => setIsModel(true)}
                      >
                        NEW EXCEL UPLOAD
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="row  m-0 p-0"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <div className="col-12 d-flex justify-content-end align-items-center p-0 m-0">
                  {selectedItems && selectedItems?.length > 0 ? (
                    <>
                      {selectedItems?.map((item) => {
                        return (
                          <>
                            <span
                              key={item?.value + item?.label}
                              style={{
                                marginLeft: "5px",
                                padding: "5px",
                                border: "1px solid #ccc",
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              {item?.label}
                              <button
                                onClick={() => handleRemoveItem(item)} // Remove item when clicked
                                style={{
                                  marginLeft: "5px",
                                  background: "red",
                                  color: "white",
                                  border: "none",
                                  padding: "0px 6px",
                                  cursor: "pointer",
                                }}
                              >
                                X
                              </button>
                            </span>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {/* <p className="p-0 m-0 filter-content">
                        No categories selected. Please choose a category to
                        proceed
                      </p> */}
                    </>
                  )}
                </div>
              </div>

              <div className="filter-table-block my-1">
                <TableContainer className="filter-table">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="product_table_heading">
                          S.No
                        </TableCell>
                        {/* <TableCell className="product_table_heading">
                        File No
                      </TableCell> */}
                        <TableCell className="product_table_heading">
                          Date Of Visit
                        </TableCell>
                        <TableCell className="product_table_heading">
                          In Time / Out Time
                        </TableCell>
                        <TableCell className="product_table_heading">
                          Name / Age / Gender
                          <div className="d-flex gap-2 align-items-center">
                            <button
                              className="filter-button"
                              onClick={() => {
                                setSortOrder("atoz");
                                setDobSortOrder(null);
                              }}
                              style={{
                                fontSize: "5px",
                                textAlign: "center",
                              }}
                            >
                              <img
                                src={Images?.arrowdown}
                                alt="no-image"
                                style={{ width: "100%", height: "100%" }}
                              />
                            </button>
                            <button
                              className="filter-button"
                              onClick={() => {
                                setSortOrder("ztoa");
                                setDobSortOrder(null);
                              }}
                              style={{
                                fontSize: "5px",
                                textAlign: "center",
                              }}
                            >
                              <img
                                src={Images?.arrowup}
                                alt="no-images"
                                style={{ width: "100%", height: "100%" }}
                              />
                            </button>
                          </div>
                        </TableCell>
                        <TableCell className="product_table_heading">
                          {/* Name of Parents/Guardian/Spouse/Children/Others & Contact No/ */}
                          S/O
                        </TableCell>
                        <TableCell className="product_table_heading">
                          DOB
                          <div className="d-flex gap-2 align-items-center">
                            <button
                              className="filter-button"
                              onClick={() => {
                                setDobSortOrder("dobAscending");
                                setSortOrder(null);
                              }}
                              style={{
                                fontSize: "5px",
                                textAlign: "center",
                              }}
                            >
                              <img
                                src={Images?.arrowdown}
                                alt="no-image"
                                style={{ width: "100%", height: "100%" }}
                              />
                            </button>
                            <button
                              className="filter-button"
                              onClick={() => {
                                setDobSortOrder("dobDescending");
                                setSortOrder(null);
                              }}
                              style={{
                                fontSize: "5px",
                                textAlign: "center",
                              }}
                            >
                              <img
                                src={Images?.arrowup}
                                alt="no-images"
                                style={{ width: "100%", height: "100%" }}
                              />
                            </button>
                          </div>
                        </TableCell>
                        <TableCell className="product_table_heading">
                          Religion/Caste
                        </TableCell>
                        <TableCell className="product_table_heading">
                          Mobile / Alternative Number
                        </TableCell>
                        {/* <TableCell className="product_table_heading">
                        Place Of Residence
                      </TableCell> */}
                        <TableCell className="product_table_heading">
                          Whatsapp Number
                        </TableCell>
                        {/* <TableCell className="product_table_heading">
                        State, Place
                      </TableCell> */}
                        <TableCell className="product_table_heading">
                          EMail
                        </TableCell>
                        <TableCell className="product_table_heading">
                          Category
                        </TableCell>
                        <TableCell className="product_table_heading">
                          Reference
                        </TableCell>
                        <TableCell className="product_table_heading">
                          Purpose Of Visit
                        </TableCell>
                        <TableCell className="product_table_heading">
                          Address
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoading ? (
                        <Loading />
                      ) : sortedData && sortedData?.length > 0 ? (
                        sortedData?.map((item, i) => (
                          <TableRow key={i}>
                            <TableCell className="product_table_data">
                              {(item && item?.sno) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.dateOfVisit) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.inOutTime) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.nameAgeGender) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.so) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {/* {moment((item && item?.dob)).format('DD-MM-YYYY')} */}
                              {(item && item?.dob) || "-"}
                              {/* {moment(item && item?.dob, "ddd MMM DD HH:mm:ss Z YYYY").format("DD/MM/YYYY")} */}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.religionCaste) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.mobileNo) || "-"}
                            </TableCell>

                            <TableCell className="product_table_data">
                              {(item && item?.whatsappNo) || "-"}
                            </TableCell>

                            <TableCell className="product_table_data">
                              {(item && item?.email) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.category) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.reference) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.purposeOfVisit) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.address) || "-"}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={23}>No data available</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </>
          )}
          {selectedDetail == "client" && (
            <>
              <div className="row pt-2 pb-2">
                <div className="col-4  d-flex align-items-center gap-1">
                  <div
                    style={{ width: "50px", height: "40px", cursor: "pointer" }}
                    onClick={() => navigate(-1)}
                  >
                    <img
                      src={Images?.arrowBack}
                      style={{ width: "100%", height: "100%" }}
                      alt="no-image"
                    />
                  </div>
                  <h6 className="client-view-head pt-2">Client File Data</h6>
                </div>
                <div className="col-8 d-flex align-items-center justify-content-end gap-2">
                  <div className="search-block-client-view">
                    <input
                      type="search"
                      className="table-search"
                      value={searchTerm}
                      placeholder="Search by caseFileNo, name, age, mobNo, emailID, place"
                      title="Search by caseFileNo, name, age, mobNo, emailID, place"
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className="image_icon">
                      <img
                        src={Images?.searchIcon}
                        style={{ width: "100%", height: "100%" }}
                        alt="Search"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end  me-4">
                    <div style={{ width: "100%" }}>
                      <div
                        className="fileNewUpload"
                        style={{ width: "100%" }}
                        onClick={() => setIsModel(true)}
                      >
                        NEW EXCEL UPLOAD
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-table-block mb-1">
                <TableContainer className="filter-table">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="product_table_heading">
                          Case File No
                        </TableCell>
                        <TableCell className="product_table_heading">
                          Date Of Registration
                        </TableCell>
                        <TableCell className="product_table_heading">
                          Client's Name
                          <div className="d-flex gap-2 align-items-center">
                            <button
                              className="filter-button"
                              onClick={() => setSortOrder("atoz")}
                              style={{
                                width: "70px",
                                height: "30px",
                                fontSize: "12px",
                                textAlign: "center",
                                lineHeight: "normal",
                              }}
                            >
                              <img
                                src={Images?.arrowdown}
                                alt="no-image"
                                style={{ width: "100%", height: "100%" }}
                              />
                            </button>
                            <button
                              className="filter-button"
                              onClick={() => setSortOrder("ztoa")}
                              style={{
                                width: "70px",
                                height: "30px",
                                fontSize: "12px",
                                textAlign: "center",
                                lineHeight: "normal",
                              }}
                            >
                              <img
                                src={Images?.arrowup}
                                alt="no-images"
                                style={{ width: "100%", height: "100%" }}
                              />
                            </button>
                          </div>
                        </TableCell>
                        <TableCell className="product_table_heading">
                          Client's Age
                        </TableCell>
                        <TableCell className="product_table_heading">
                          Client's Credentials
                        </TableCell>
                        <TableCell className="product_table_heading">
                          Name of Parents/Guardian/Spouse/Children/Others &
                          Contact No/
                        </TableCell>
                        <TableCell className="product_table_heading">
                          Client's Mob No
                        </TableCell>
                        <TableCell className="product_table_heading">
                          Email Id, if any
                        </TableCell>
                        <TableCell className="product_table_heading">
                          Client's Address & Add1 Info
                        </TableCell>
                        <TableCell className="product_table_heading">
                          Subject's Name
                        </TableCell>
                        <TableCell className="product_table_heading">
                          Relationship between Subject & Client
                        </TableCell>
                        <TableCell className="product_table_heading">
                          Subject's Mobile No
                        </TableCell>
                        <TableCell className="product_table_heading">
                          Name Of Parents/Guardian/Spouse/Others & Mob No :
                          other Add1 info:If any
                        </TableCell>
                        <TableCell className="product_table_heading">
                          Subject's Res.Address
                        </TableCell>
                        <TableCell className="product_table_heading">
                          NATURE OF CASE(Title)
                        </TableCell>
                        <TableCell className="product_table_heading">
                          NATURE OF CASE(Details)
                        </TableCell>
                        <TableCell className="product_table_heading">
                          NATURE OF CASE(Client's Requirements)
                        </TableCell>
                        <TableCell className="product_table_heading">
                          Reference(JD/GS/Others)
                        </TableCell>
                        <TableCell className="product_table_heading">
                          List Of Investigations
                        </TableCell>
                        <TableCell className="product_table_heading">
                          Date Of Closing
                        </TableCell>
                        <TableCell className="product_table_heading">
                          File No
                        </TableCell>
                        <TableCell className="product_table_heading">
                          Reason For Closure
                        </TableCell>
                        <TableCell className="product_table_heading">
                          Conferencial Report
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoading ? (
                        <Loading />
                      ) : clientInfo && clientInfo?.length > 0 ? (
                        clientInfo?.map((item, i) => (
                          <TableRow key={i}>
                            <TableCell className="product_table_data">
                              {(item && item?.caseFileNo) || "-"}
                            </TableCell>

                            <TableCell className="product_table_data">
                              {(item && item?.dateOfRegistration) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.clientName) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.clientAge) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.clientCrendentials) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.nameOfParents) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.clientMobNo) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.emailId) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.clientAddress) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.subjectName) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.relationship) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.subjectMobNo) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.nameOfParentsTwo) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.subjectResAddress) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.natureOfCaseTitle) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.natureOfCaseDetails) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.natureOfCaseRequirement) || "-"}
                            </TableCell>

                            <TableCell className="product_table_data">
                              {(item && item?.reference) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.investigation) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.dateOfClosing) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.fileNo) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.reasonClosure) || "-"}
                            </TableCell>
                            <TableCell className="product_table_data">
                              {(item && item?.cr) || "-"}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={23}>No data available</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </>
          )}
        </div>
      </div>
      <div>
        <MuiExcelUploadDialog open={isModel} onClose={setIsModel} />
      </div>
    </>
  );
}
