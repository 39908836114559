// import React from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
// import companyLogo from "../asset/logo.jpg";
// import Images from "../asset";
// export default function Sidebar() {
//   const navigate = useNavigate();
//   const userdata = sessionStorage.getItem("userdata");
//   return (
//     <div>
//      <Sidebar className="d-flex flex-column vh-100  pt-2"
//       style={{ backgroundColor: " #D9D9D9" }}>

//     </Sidebar>
//     <div
//       className="d-flex flex-column vh-100  pt-2"
//       style={{ backgroundColor: " #D9D9D9" }}
//     >
//       <div className="d-flex justify-content-center">
//         <Link
//           to="/"
//           className="nav-link"
//           style={{ zIndex: 9, padding: "5px 10px" }}
//         >
//           <img
//             src={Images?.loginlogoIcon}
//             alt="logo"
//             style={{ width: "100%", height: "100px", borderRadius: "10px" }}
//           />
//         </Link>
//       </div>
//       <ul
//         className="nav nav-pills flex-column mb-auto pt-3 gap-1"
//         style={{ backgroundColor: " #D9D9D9" }}
//       >
//         <li className="nav-item sidebarList">
//           <Link to="/visitorAdd" className="nav-link sidebarListName ">
//             NEW VISITOR
//           </Link>
//         </li>

//         <li className="nav-item sidebarList">
//           <Link to="/" className="nav-link sidebarListName">
//             VIEW VISITOR
//           </Link>
//         </li>
//         <li className="nav-item sidebarList">
//           <Link to="/filterData" className="nav-link sidebarListName">
//             FILTER
//           </Link>
//         </li>
//         {userdata == "superAdmin" ? (
//           <>
//             <li className="nav-item sidebarList">
//               <Link to="/userRegister" className="nav-link sidebarListName">
//                 NEW REGISTER
//               </Link>
//             </li>
//             <li className="nav-item sidebarList">
//               <Link to="/viewRegister" className="nav-link sidebarListName">
//                 VIEW REGISTER
//               </Link>
//             </li>
//           </>
//         ) : (
//           <></>
//         )}
//         <li
//           className="nav-item sidebarList"
//           onClick={() => {
//             sessionStorage.removeItem("login");
//             window.location?.reload();
//             // navigate("/")
//           }}
//         >
//           <Link to="/" className="nav-link sidebarListName">
//             LOG OUT
//           </Link>
//         </li>
//       </ul>
//     </div>
//     </div>
//   );
// }

import Images from "../asset";
import React from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useNavigate } from "react-router-dom";
export default function SidebarMenu() {
  const navigate = useNavigate();
  const userData = sessionStorage.getItem("userdata");
  return (
    <div>
      <Sidebar style={{ width: "100%" }}>
        <Menu
          className="d-flex flex-column   "
          style={{ backgroundColor: " #D9D9D9", height: "100vh" }}
        >
          <div
            className="d-flex justify-content-center py-2"
            style={{ height: "auto" }}
          >
            <img
              src={Images?.loginlogoIcon}
              alt="logo"
              style={{
                width: "auto",
                height: "auto",
                borderRadius: "10px",
                padding: "0px",
              }}
            />
          </div>
          <SubMenu className="sideBarMenuItem" label="Visitors Information" defaultOpen>
          <MenuItem
              component={<Link to="/visitorEntry" />}
              className="sideBarMenuItem"
            >
              {" "}
              Visitor Entry{" "}
            </MenuItem>
            <MenuItem component={<Link to="/" />} className="sideBarMenuItem">
              {" "}
              Visitors {" "}
            </MenuItem>
           
          </SubMenu>
          <SubMenu className="sideBarMenuItem" label="Clients Information" defaultOpen>
            
            <MenuItem
              component={<Link to="/clientEntry" />}
              className="sideBarMenuItem"
            >
              {" "}
              Client Entry{" "}
            </MenuItem>
            <MenuItem
              component={<Link to="/clientList" />}
              className="sideBarMenuItem"
            >
              {" "}
              Clients {" "}
            </MenuItem>
          </SubMenu>
          <MenuItem
            component={<Link to="/filterData" />}
            className="sideBarMenuItem"
          >
            Search
          </MenuItem>
          <MenuItem
            component={<Link to="/fileUpload" />}
            className="sideBarMenuItem"
          >
            File Upload
          </MenuItem>
          {userData === "superAdmin" && (
            <SubMenu className="sideBarMenuItem" label="User Management">
              <MenuItem
                component={<Link to="/viewRegister" />}
                className="sideBarMenuItem"
              >
                {" "}
                Existing User{" "}
              </MenuItem>
              <MenuItem
                component={<Link to="/userRegister" />}
                className="sideBarMenuItem"
              >
                {" "}
                Add New User{" "}
              </MenuItem>
            </SubMenu>
          )}
          {/* {
            userData === "superAdmin" && (

              <MenuItem
                onClick={() => {
                  navigate("/fileUpload");
                }}
                // component={<Link to="/" />}
                className="fw-bold sidebarListName"
              >
                FileUpload
              </MenuItem>
            )
          } */}
          <MenuItem
            onClick={() => {
              sessionStorage.removeItem("login");
              sessionStorage.removeItem("userdata");
              navigate("/");
              window.location.reload();
            }}
            // component={<Link to="/" />}
            className="fw-bold sidebarListName"
          >
            Log out
          </MenuItem>
        </Menu>
      </Sidebar>
    </div>
  );
}
