import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import PinInput from "react-pin-input";
import InputField from "./InputField";
import { stateDistricts } from "./State";
import add from "../asset/Add.png";
import minus from "../asset/minus.png";
import { Button } from "bootstrap";
import Modal from "./Modal";
import { useMutation } from "react-query";
import axios from "axios";
import { useLocation, useNavigate } from "react-router";
import Alert from "./Alert";
import AddressModal from "./AddressModal";
import wrongIcon from "../asset/close.svg";
import { set, subYears } from "date-fns";
import added from "../asset/added.svg";

export default function VisitorInformationCom() {
  const [alsoWhatApp, setAlsoWhatApp] = useState();
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentField, setCurrentField] = useState([]);
  const [postAlert, setPostAlert] = useState();
  const [putAlert, setPutAlert] = useState();
  const [mobileNumberData, setMobileNumberData] = useState();
  const [districts, setDistricts] = useState([]);
  const [alsoPermanent, setAlsoPermanent] = useState();
  const [showPermanentAdd, setShowPermanentAdd] = useState(null);
  const [showPresentAdd, setShowPresentAdd] = useState(null);
  const [showOfficeAdd, setShowOfficeAdd] = useState(null);
  const [showOfficeAppend, setOfficeAppend] = useState(null);
  const [showPermanentAppend, setShowPermanentAppend] = useState(null);
  const [showPermanentAddress, setShowPermanentAddress] = useState(null);
  const [showPresentAddAppend, setShowPresentAddAppend] = useState(null);

  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("showPermanentAdd", showPermanentAdd);

  const { control, handleSubmit, setValue, watch, register, getValues } =
    useForm({
      defaultValues: {
        userForm: [],
      },
    });
  const { fields, append, remove } = useFieldArray({
    name: "userForm",
    control,
  });

  const {
    userForm,
    mobileNumber,
    inTime,
    religion,
    category,
    sourceForm,
    permanentAddNo,
    permanentAddEmail,
    permanentAdditionalDetails,
    permanentAddress,
    officeAddNo,
    officeAddEmail,
    officeAdditionalDetails,
    officeAddress,
    temporaryAddress,
  } = watch();
  console.log("state", state);

  useEffect(() => {
    if (state) {
      setValue("name", state && state?.name);
      setValue(
        "mobileNumber",
        state && state?.mobileNumber ? state?.mobileNumber : ""
      );
      setValue("category", state?.category);
      setValue("gender", state?.gender);
      setValue("age", state?.age);
      setValue("fileNumber", state?.fileNumber);
      setValue(
        "alternativeNumber",
        state && state?.alternativeNumber ? state?.alternativeNumber : ""
      );
      setValue("sourceForm", state?.sourceForm);
      setValue("permanentAddress", state?.permanentAddress);
      setValue("temporaryAddress", state?.temporaryAddress);
      setValue("officeAddress", state?.officeAddress);
      // setValue("placeOfResidence", state?.placeOfResidence);
      setValue("district", state && state?.district);
      setValue("pincode", state?.pincode);
      setValue("inTime", state?.inTime);
      setValue("outTime", state?.outTime);
      setValue("dateOfVisit", state?.dateOfVisit  === "Invalid date" ? "" : state?.dateOfVisit);
      setValue("dateOfAppointment", state?.dateOfAppointment  === "Invalid date" ? "" : state?.dateOfAppointment);
      setValue("additionalStatus", state?.additionalStatus);
      setValue("state", state && state?.state);
      setValue("email", state?.email);
      setValue("whatsapp", state && state?.whatsapp ? state?.whatsapp : "");
      setValue("caste", state?.caste);
      setValue("religion", state?.religion);
      setValue("date", state?.date);
      setValue("otherReligion", state?.otherReligion);
      setValue("otherSourceForm", state?.otherSourceForm);
      setValue("otherCategory", state?.otherCategory);
      setValue("permanentAddNo", state?.permanentAddNo);
      setValue("permanentAddEmail", state?.permanentAddEmail);
      setValue("officeAddNo", state?.officeAddNo);
      setValue("officeAddEmail", state?.officeAddEmail);
      setValue("country", state?.country);
      setValue("fatherName", state?.fatherName);
      setValue("dob", state?.dob === "Invalid date" ? "" : state?.dob);
      setValue("purposeOfVisit", state?.purposeOfVisit);
      setValue("officeAdditionalDetails", state?.officeAdditionalDetails);
      setValue("permanentAdditionalDetails", state?.permanentAdditionalDetails);
      setValue("visitorNote1", state?.visitorNote1);
      setValue("visitorNote2", state?.visitorNote2);
      setAlsoWhatApp(true);
      if (state?.duplicatevisitor) {
        append();
        remove();
        state?.duplicatevisitor?.forEach((value, index) => {
          setValue(`userForm.${index}.name`, value && value?.name);
          setValue(`userForm.${index}.fileNumber`, value && value?.fileNumber);
          setValue(
            `userForm.${index}.mobileNumber`,
            value && value?.mobileNumber ? value?.mobileNumber : ""
          );
          setValue(
            `userForm.${index}.alternativeNumber`,
            value && value?.alternativeNumber ? value?.alternativeNumber : ""
          );
          setValue(
            `userForm.${index}.whatsapp`,
            value && value?.whatsapp ? value?.whatsapp : ""
          );
          setValue(`userForm.${index}.email`, value && value?.email);
          setValue(`userForm.${index}.caste`, value && value?.caste);
          setValue(`userForm.${index}.gender`, value && value?.gender);
          setValue(`userForm.${index}.relegion`, value && value?.relegion);
          setValue(
            `userForm.${index}.temporaryAddress`,
            value && value?.temporaryAddress
          );
          setValue(
            `userForm.${index}.permanentAddress`,
            value && value?.permanentAddress
          );
          setValue(
            `userForm.${index}.officeAddress`,
            value && value?.officeAddress
          );
          setValue(
            `userForm.${index}.dateOfVisit`,
            // value && value?.dateOfVisit !== null
            value && value?.dateOfVisit  === "Invalid date" ? "" : value?.dateOfVisit
          );
          // setValue(
          //   `userForm.${index}.dateOfVisit`,
          //   value && value?.dateOfVisit !== null
          //     ? moment(value && value?.dateOfVisit, "DD-MM-YYYY").format(
          //         "MM/DD/YYYY"
          //       )
          //     : ""
          // );
          // setValue(
          //   `userForm.${index}.dateOfAppointment`,
          //   value && value?.dateOfAppointment !== null
          //     ? moment(value && value?.dateOfAppoinment, "DD-MM-YYYY").format(
          //         "MM/DD/YYYY"
          //       )
          //     : ""
          // );
          setValue(
            `userForm.${index}.dateOfAppointment`,
            value && value?.dateOfAppointment  === "Invalid date" ? "" : value?.dateOfAppointment
          );
          setValue(`userForm.${index}.category`, value && value?.category);
          setValue(`userForm.${index}.sourceForm`, value && value?.sourceForm);
          setValue(`userForm.${index}.age`, value && value?.age);
          setValue(
            `userForm.${index}.placeOfResidence`,
            value && value?.placeOfResidence
          );
          setValue(`userForm.${index}.address`, value && value?.address);
          setValue(`userForm.${index}.district`, value && value?.district);
          setValue(`userForm.${index}.state`, value && value?.state);
          setValue(`userForm.${index}.pincode`, value && value?.pincode);
          setValue(
            `userForm.${index}.additionalStatus`,
            value && value?.additionalStatus
          );
          setValue(
            `userForm.${index}.purposeOfVisit`,
            value && value?.additionalStatus
          );
          setValue(
            `userForm.${index}.otherReligion`,
            value && value?.otherReligion
          );
          setValue(
            `userForm.${index}.otherSourceForm`,
            value && value?.otherSourceForm
          );
          setValue(
            `userForm.${index}.otherCategory`,
            value && value?.otherCategory
          );
          setValue(
            `userForm.${index}.permanentAddNo`,
            value && value?.permanentAddNo
          );
          setValue(
            `userForm.${index}.permanentAddEmail`,
            value && value?.permanentAddEmail
          );
          setValue(
            `userForm.${index}.permanentAdditionalDetails`,
            value && value?.permanentAdditionalDetails
          );
          setValue(
            `userForm.${index}.officeAddNo`,
            value && value?.officeAddNo
          );
          setValue(
            `userForm.${index}.officeAddEmail`,
            value && value?.officeAddEmail
          );
          setValue(
            `userForm.${index}.officeAdditionalDetails`,
            value && value?.officeAdditionalDetails
          );

          setValue(`userForm.${index}.country`, value && value?.country);
          setValue(`userForm.${index}.fatherName`, value && value?.fatherName);
          setValue(`userForm.${index}.dob`, value && value?.dob  === "Invalid date" ? "" : value?.dob);
          setValue(`userForm.${index}.inTime`, value && value?.inTime);
          setValue(`userForm.${index}.outTime`, value && value?.outTime);
          setValue(`userForm.${index}.visitorNote1`, value && value?.visitorNote1);
          setValue(`userForm.${index}.visitorNote2`, value && value?.visitorNote2);
        });
      }
    }
  }, [state]);

  const { mutate, isLoading } = useMutation(
    (postData) =>
      axios.post("https://api.2kvirtualworld.com/DADEV/newvisitors", postData),
    {
      onSuccess: (data) => {
        // setPostAlert(true);
        navigate("/");
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );

  const upData = useMutation(
    (postData) =>
      axios.put(
        `https://api.2kvirtualworld.com/DADEV/newvisitors/${state?.id}`,
        postData
      ),
    {
      // You can define callbacks for different stages of the mutation
      onSuccess: () => {
        // setPutAlert(true);
        navigate("/");
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );

  function calculateAge(birthDate, index) {
    console.log("birthDate", moment().diff(moment(birthDate), "years"), index);
    setValue("age", moment().diff(moment(birthDate), "years") || "");
    if (birthDate && index === 0) {
      userForm?.map((items) => {
        console.log("welcome");
        if (index === 0) {
          setValue(
            `userForm.${index}.age`,
            moment().diff(moment(birthDate), "years") || ""
          );
        }
      });
    } else if (birthDate && index) {
      userForm?.map((items) => {
        // console.log("welcome");
        if (index === 0) {
          setValue(
            `userForm.${index}.age`,
            moment().diff(moment(birthDate), "years") || ""
          );
        } else {
          setValue(
            `userForm.${index}.age`,
            moment().diff(moment(birthDate), "years") || ""
          );
        }
      });
    }
    // return moment().diff(moment(birthDate), "years");
  }

  const onSubmit = (data) => {
    const formData = {
      name: data?.name,
      mobileNumber: data?.mobileNumber,
      category: data?.category,
      gender: data?.gender,
      age: data?.age,
      // fileNumber: data?.fileNumber,
      alternativeNumber: data?.alternativeNumber,
      sourceForm: data?.sourceForm,
      permanentAddress: data?.permanentAddress,
      temporaryAddress: data?.temporaryAddress,
      officeAddress: data?.officeAddress,
      placeOfResidence: data?.placeOfResidence,
      district: data?.district,
      pincode: data?.pincode,
      inTime: data?.inTime,
      outTime: data?.outTime,
      state: data?.state,
      dateOfVisit: data?.dateOfVisit,
      dateOfAppointment: data?.dateOfAppointment,
      additionalStatus: data?.additionalStatus,
      status: data?.status,
      email: data?.email,
      whatsapp: data?.whatsapp,
      caste: data?.caste,
      religion: data?.religion,
      date: data?.date,
      otherReligion: data?.otherReligion,
      otherSourceForm: data?.otherSourceForm,
      otherCategory: data?.otherCategory,
      permanentAddNo: data?.permanentAddNo,
      permanentAddEmail: data?.permanentAddEmail,
      officeAddNo: data?.officeAddNo,
      officeAddEmail: data?.officeAddEmail,
      country: data?.country,
      fatherName: data?.fatherName,
      dob: moment(data?.dob ? data?.dob : "").format("YYYY-MM-DD"),
      purposeOfVisit: data?.purposeOfVisit,
      permanentAdditionalDetails: data?.permanentAdditionalDetails,
      officeAdditionalDetails: data?.officeAdditionalDetails,
      visitorNote1: data?.visitorNote1,
      visitorNote2: data?.visitorNote2,
      duplicatevisitor: userForm,
    };
    console.log("formData", formData);
    setPostAlert(formData);
  };

  const handleFocus = (fieldName, labelName, currentValue = "") => {
    setCurrentField({ fieldName, labelName, value: currentValue });
    setModalOpen(true); // Open modal on focus
  };

  // Save the updated value from the modal and close it
  const handleSave = (value) => {
    if (currentField) {
      setValue(currentField?.fieldName, value || ""); // Update form field with modal input
    }
    setModalOpen(false);
    setCurrentField(null);
  };

  const addHandleFouce = () => {};
  const mobileNumberCheckBox = (e, index) => {
    const checked = e.target.checked;

    if (checked) {
      console.log("checked", checked, index);
      userForm?.forEach((user, idx) => {
        // setValue("whatsapp", user.mobileNumber);
        setValue(`userForm.${index}.whatsapp`, user.mobileNumber);
      });
      setAlsoWhatApp(true);
    } else {
      userForm?.forEach((user, idx) => {
        setValue(`userForm.${index}.whatsapp`, " ");
      });
      setAlsoWhatApp(false);
    }
  };
  const mobileCheckBox = (e, index) => {
    const checked = e.target.checked;

    if (checked) {
      setValue("whatsapp", mobileNumber);
      setAlsoWhatApp(true);
    } else {
      setValue("whatsapp", "");
      setAlsoWhatApp(false);
    }
  };

  // const addressCheckBox = (e, index) => {
  //   const checked = e.target.checked;
  //   if (checked) {
  //     userForm?.forEach((user, idx) => {
  //       setValue(`userForm.${index}.permanentAddress`, user.temporaryAddress);
  //     });
  //     setAlsoPermanent(true);
  //   } else {
  //     userForm?.forEach((user, idx) => {
  //       setValue(`userForm.${index}.permanentAddress`, " ");
  //     });
  //     setAlsoPermanent(false);
  //   }
  // };

  // useEffect(()=>{
  //   console.log('window',window);

  //   window.scrollTo(0,1000)
  // },[userForm])

  const allDistricts = Object.values(stateDistricts).flat();
  useEffect(() => {
    userForm?.map((ele, i) => {
      setDistricts(stateDistricts[ele?.state] || []);
    });
  }, [userForm]);
  console.log("districts", districts);
  return (
    <div className="mainContainerDiv" style={{ overflow: "scroll" }}>
      <section>
        <div className="headerContainer">
          <header className="visitorEntryHeader">Visitor Entry</header>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="visitorEntryFormInput">
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">Name</label>
                  </div>
                  <Controller
                    control={control}
                    name="name"
                    render={(field) => (
                      <InputField
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">S/o</label>
                  </div>
                  <Controller
                    control={control}
                    name="fatherName"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="text"
                      />
                    )}
                  />
                </div>
                <div style={{ width: "18%", display: "flex" }}>
                  <div
                    className="visitorEntryInputContainer"
                    style={{ width: "78%", marginRight: "2%" }}
                  >
                    <div>
                      <label className="visitorInfoLabelContainer">DOB</label>
                    </div>
                    <Controller
                      control={control}
                      name="dob"
                      render={({ field }) => (
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="visiterInfoCss rounded-start"
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={41} // Includes the current year + 40 previous years
                          selected={field.value}
                          maxDate={new Date()} // Restrict to current date and earlier
                          minDate={subYears(new Date(), 40)} // Restrict to 40 years before the current date
                          onChange={(date) => {
                            field.onChange(date);
                            calculateAge(date);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div
                    className="visitorEntryInputContainer"
                    style={{ width: "20%" }}
                  >
                    <div>
                      <label className="visitorInfoLabelContainer">Age</label>
                    </div>
                    <Controller
                      control={control}
                      name="age"
                      render={(field) => (
                        <InputField
                          {...field}
                          visiterInfoCss="visiterInfoCss"
                          type="text"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">Gender</label>
                  </div>
                  <Controller
                    control={control}
                    name="gender"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        value={value}
                        className="visiterInfoCss py-2"
                        onChange={onChange}
                        onBlur={onBlur} // Added onBlur for better form validation handling
                        ref={ref} // Make sure the ref is passed down for proper field tracking
                      >
                        <option value=""> </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Religion
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="religion"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        value={value}
                        className="visiterInfoCss py-2"
                        onChange={onChange}
                        onBlur={onBlur} // Added onBlur for better form validation handling
                        ref={ref} // Make sure the ref is passed down for proper field tracking
                      >
                        <option value=""> </option>
                        <option value="Christianity">Christianity</option>
                        <option value="Islam">Islam</option>
                        <option value="Hinduism">Hinduism</option>
                        <option value="Other">Other</option>
                      </select>
                    )}
                  />
                </div>
                {religion === "Other" && (
                  <div className="visitorEntryInputContainer">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Other Religion
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="otherReligion"
                      render={(field) => (
                        <InputField
                          {...field}
                          visiterInfoCss="visiterInfoCss"
                          type="text"
                        />
                      )}
                    />
                  </div>
                )}

                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">Caste</label>
                  </div>
                  <Controller
                    control={control}
                    name="caste"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="visitorInfoMobile">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Mobile Number
                    </label>
                  </div>
                  <Controller
                    key={
                      alsoWhatApp ? "whatsapp-synced" : "whatsapp-independent"
                    }
                    control={control}
                    name="mobileNumber"
                    rules={{
                      minLength: {
                        value: 10,
                        message: "Mobile number must be 10 digits",
                      },
                      maxLength: {
                        value: 10,
                        message: "Mobile number must be 10 digits",
                      },
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Please enter a valid 10-digit mobile number",
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <PinInput
                          length={10}
                          initialValue={value || ""}
                          onChange={(val) => {
                            onChange(val);

                            if (alsoWhatApp && val?.length === 10) {
                              setValue("whatsapp", val);
                            }
                          }}
                          type="numeric"
                          style={{ width: "100%", borderRadius: "5px" }}
                          inputStyle={{
                            border: "1px solid gray",
                            backgroundColor: "#fff",
                            height: "40px",
                            width: "10%",
                            margin: "0px",
                            borderRadius: "5px",
                          }}
                          autoSelect
                          onComplete={(value, index) => {
                            console.log("onComplete", value);
                            setMobileNumberData(value);
                          }}
                        />
                        {error && (
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginTop: "5px",
                            }}
                          >
                            {error.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                </div>
                {/* <div className="visitorInfoMobile">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Alternative Number
                    </label>
                  </div>
                  <Controller
                    key={
                      alsoWhatApp ? "whatsapp-synced" : "whatsapp-independent"
                    }
                    control={control}
                    name="alternativeNumber"
                    rules={{
                      minLength: {
                        value: 10,
                        message: "Mobile number must be 10 digits", // Error message if length is less than 10
                      },
                      maxLength: {
                        value: 10,
                        message: "Mobile number must be 10 digits", // Error message if length is more than 10
                      },
                      pattern: {
                        value: /^[0-9]{10}$/, // Ensure the input is numeric and exactly 10 digits
                        message: "Please enter a valid 10-digit mobile number", // Custom error message
                      },
                    }}
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState: { error },
                    }) => (
                      <>
                        <PinInput
                          length={10}
                          initialValue={value || ""}
                          onChange={onChange}
                          type="numeric"
                          style={{ width: "100%", borderRadius: "5px" }}
                          inputStyle={{
                            border: "1px solid gray",
                            backgroundColor: "#fff",
                            height: "40px",
                            width: "10%",
                            margin: "0px",
                            borderRadius: "5px",
                          }}
                          autoSelect={true}
                        />

                        {error && (
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginTop: "5px",
                            }}
                          >
                            {error?.message}{" "}
                          </span>
                        )}
                      </>
                    )}
                  />
                </div> */}
                <div className="visitorInfoMobile">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      WhatsApp Number
                    </label>
                  </div>
                  <Controller
                    key={
                      alsoWhatApp ? "whatsapp-synced" : "whatsapp-independent"
                    } // Unique key for re-rendering
                    control={control}
                    name="whatsapp"
                    rules={{
                      minLength: {
                        value: 10,
                        message: "Mobile number must be 10 digits", // Error message when length is less than 10
                      },
                      maxLength: {
                        value: 10,
                        message: "Mobile number must be 10 digits", // Error message when length is more than 10
                      },
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Please enter a valid 10-digit mobile number", // Ensure it's numeric
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <PinInput
                        length={10}
                        initialValue={value}
                        value={value || ""}
                        onChange={(val) => onChange(val)}
                        type="numeric"
                        style={{ width: "100%", borderRadius: "5px" }}
                        inputStyle={{
                          border: "1px solid gray",
                          backgroundColor: "#fff",
                          height: "40px",
                          width: "10%",
                          margin: "0px",
                          borderRadius: "5px",
                        }}
                        autoSelect
                      />
                    )}
                  />
                  <div
                    style={{ fontSize: "12px", cursor: "pointer" }}
                    className="d-flex pt-1 justify-content-end"
                  >
                    <label className="d-flex gap-1 align-items-center">
                      <input
                        onChange={(e) => {
                          // mobileNumberCheckBox(e);
                          mobileCheckBox(e);
                        }}
                        type="checkbox"
                        style={{ width: "15px", height: "15px" }}
                      />
                      Also Same As Above
                    </label>
                  </div>
                </div>

                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Landline Number
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="alternativeNumber"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="number"
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">E Mail</label>
                  </div>
                  <Controller
                    control={control}
                    name="email"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="email"
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Category
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="category"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        className="visiterInfoCss"
                        onChange={onChange}
                        value={value}
                      >
                        <option value=" "> </option>
                        <option value="Visitor">Visitor</option>
                        <option value="Client">Client</option>
                        <option value="Perspective">Perspective</option>
                        <option value="Business">Business</option>
                        <option value="Interview">Interview</option>

                        <option value="Other">Other</option>
                      </select>
                    )}
                  />
                </div>
                {category === "Other" && (
                  <div className="visitorEntryInputContainer">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        other Category
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="otherCategory"
                      render={(field) => (
                        <InputField
                          {...field}
                          visiterInfoCss="visiterInfoCss"
                          type="text"
                        />
                      )}
                    />
                  </div>
                )}
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Reference
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="sourceForm"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        value={value}
                        className="visiterInfoCss"
                        onChange={onChange}
                      >
                        <option value=" "> </option>
                        <option value="JustDial">Just Dial</option>
                        <option value="YouTube">YouTube</option>
                        <option value="Google">Google</option>
                        <option value="Advertisement">Advertisement</option>
                        <option value="SocialMedia">Social Media</option>
                        <option value="Referred">Referred By</option>
                        <option value="Other">Other</option>
                      </select>
                    )}
                  />
                </div>
                {sourceForm === "Other" && (
                  <div className="visitorEntryInputContainer">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Other Reference
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="otherSourceForm"
                      render={(field) => (
                        <InputField
                          {...field}
                          visiterInfoCss="visiterInfoCss"
                          type="text"
                        />
                      )}
                    />
                  </div>
                )}
                {sourceForm === "SocialMedia" && (
                  <div className="visitorEntryInputContainer">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Social Media Name
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="otherSourceForm"
                      render={(field) => (
                        <InputField
                          {...field}
                          visiterInfoCss="visiterInfoCss"
                          type="text"
                        />
                      )}
                    />
                  </div>
                )}
                {sourceForm === "Referred" && (
                  <div className="visitorEntryInputContainer">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Reference By
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="otherSourceForm"
                      render={(field) => (
                        <InputField
                          {...field}
                          visiterInfoCss="visiterInfoCss"
                          type="text"
                        />
                      )}
                    />
                  </div>
                )}

                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Date of visit
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="dateOfVisit"
                    render={({ field }) => (
                      <DatePicker
                        dateFormat="dd/MM/yyyy eeee"
                        className="visiterInfoCss"
                        selected={field.value}
                        onChange={(date) => field.onChange(date)}
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Date of Entry
                    </label>
                  </div>

                  <Controller
                    control={control}
                    name="dateOfAppointment"
                    defaultValue={moment().toDate()}
                    render={({ field }) => (
                      <DatePicker
                        dateFormat="dd/MM/yyyy eeee" // Format the date to display (e.g., Mon 06/11/2024)
                        className="visiterInfoCss"
                        readOnly
                        selected={
                          field.value ? moment(field.value).toDate() : null
                        } // Convert moment to native Date
                        onChange={(date) => field.onChange(moment(date))} // Convert selected date to moment
                        minDate={moment().toDate()}
                        maxDate={moment().toDate()}
                        disabled={false}
                      />
                    )}
                  />
                </div>

                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">Status</label>
                  </div>
                  <Controller
                    control={control}
                    name="status"
                    defaultValue="Active"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        className="visiterInfoCss"
                        value={value}
                        onChange={onChange}
                      >
                        <option value=""></option>
                        <option value="Active">Active</option>
                        <option value="In Active"> In Active</option>
                      </select>
                    )}
                  />
                </div>
                <div className="d-flex" style={{ width: "18%" }}>
                  <div
                    className="visitorEntryInputContainer"
                    style={{ width: "50%" }}
                  >
                    <div>
                      <label className="visitorInfoLabelContainer">
                        In Time
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="inTime"
                      render={({ field }) => (
                        <input
                          {...field}
                          type="time"
                          className="visiterInfoCss"
                        />
                      )}
                    />
                  </div>
                  <div
                    className="visitorEntryInputContainer"
                    style={{ width: "50%" }}
                  >
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Out Time
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="outTime"
                      render={({ field }) => (
                        <input
                          {...field}
                          type="time"
                          className="visiterInfoCss"
                          // Set the min value dynamically based on In Time
                          min={inTime || ""}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Purpose of visit
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="purposeOfVisit"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <textarea
                        ref={ref}
                        style={{ height: "40px", width: "100%" }}
                        value={value}
                        className="visiterInfoCss  py-2"
                        onFocus={() =>
                          handleFocus(
                            "purposeOfVisit",
                            "Purpose of visit",
                            value
                          )
                        }
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        onBlur={onBlur}
                        disabled={isModalOpen}
                      ></textarea>
                    )}
                  />
                </div>
                <div
                  className="visitorEntryInputContainer"
                  // style={{ width: "18%", height: "50px" }}
                >
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Additional Particulars
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="additionalStatus"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <textarea
                        ref={ref}
                        style={{ height: "40px", width: "100%" }}
                        value={value}
                        className="visiterInfoCss  py-2"
                        onFocus={() =>
                          handleFocus(
                            "additionalStatus",
                            "Additional Particulars",
                            value
                          )
                        }
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        onBlur={onBlur}
                        disabled={isModalOpen}
                      ></textarea>
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">Note 1</label>
                  </div>
                  <Controller
                    control={control}
                    name="visitorNote1"
                    render={(field) => (
                      <InputField
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">Note 2</label>
                  </div>
                  <Controller
                    control={control}
                    name="visitorNote2"
                    render={(field) => (
                      <InputField
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="d-flex" style={{ width: "18%" }}>
                  <div
                    style={
                      temporaryAddress
                        ? {
                            backgroundColor: "green",
                            color: "white",
                            cursor: "pointer",
                            width: "100%",
                            height: "40px",
                            alignContent: "center",
                          }
                        : {
                            backgroundColor: "red",
                            color: "white",
                            cursor: "pointer",
                            width: "100%",
                            height: "40px",
                            alignContent: "center",
                          }
                    }
                    title={temporaryAddress || "No address available"}
                    className="visitorInfoShowBtn fs-6 px-2 text-center"
                    onClick={() => {
                      setShowPresentAdd(true);
                    }}
                  >
                    Present Address
                  </div>
                  {/* <p style={{ alignContent: "center", marginLeft: "10px" }}>
                    {temporaryAddress && <img src={added} alt="" />}
                  </p> */}
                </div>

                <div className="d-flex" style={{ width: "18%" }}>
                  <div
                    className="visitorInfoShowBtn fs-6 px-2 text-center"
                    title={officeAddress || "No address available"}
                    style={
                      officeAddNo ||
                      officeAddEmail ||
                      officeAdditionalDetails ||
                      officeAddress
                        ? {
                            backgroundColor: "green",
                            color: "white",
                            cursor: "pointer",
                            width: "100%",
                            height: "40px",
                            alignContent: "center",
                          }
                        : {
                            backgroundColor: "red",
                            color: "white",
                            cursor: "pointer",
                            width: "100%",
                            height: "40px",
                            alignContent: "center",
                          }
                    }
                    onClick={() => {
                      setShowOfficeAdd(true);
                    }}
                  >
                    {/* Office Address & Permanent Address */}
                    Office Address
                  </div>
                </div>
                <div className="d-flex" style={{ width: "18%" }}>
                  <div
                    className="visitorInfoShowBtn fs-6 px-2 text-center"
                    title={permanentAddress || "No address available"}
                    style={
                      permanentAddNo ||
                      permanentAddEmail ||
                      permanentAdditionalDetails ||
                      permanentAddress
                        ? {
                            backgroundColor: "green",
                            color: "white",
                            cursor: "pointer",
                            width: "100%",
                            height: "40px",
                            alignContent: "center",
                          }
                        : {
                            backgroundColor: "red",
                            color: "white",
                            cursor: "pointer",
                            width: "100%",
                            height: "40px",
                            alignContent: "center",
                          }
                    }
                    onClick={() => {
                      setShowPermanentAddress(true);
                    }}
                  >
                    {/* Office Address & Permanent Address */}
                    Permanent Address
                  </div>
                  {/* <p style={{ alignContent: "center", marginLeft: "10px" }}>
                    {(permanentAddNo ||
                      permanentAddEmail ||
                      permanentAdditionalDetails ||
                      permanentAddress) && <img src={added} alt="" />}
                  </p> */}
                </div>
              </div>

              <div>
                {fields?.map((field, index) => {
                  return (
                    <div
                      className="pt-5 mt-4"
                      key={field.id}
                      id={`${index}-form`}
                    >
                      <div className="headerContainer">
                        <header className="visitorEntryHeader">
                          Co-Visitor {index + 1}
                        </header>
                      </div>
                      <div className="visitorEntryFormInput">
                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Name
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.name`}
                            render={(field) => (
                              <InputField
                                visiterInfoCss="visiterInfoCss"
                                {...field}
                                type="text"
                              />
                            )}
                          />
                        </div>
                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              S/o
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.fatherName`}
                            render={(field) => (
                              <InputField
                                {...field}
                                visiterInfoCss="visiterInfoCss"
                                type="text"
                              />
                            )}
                          />
                        </div>
                        <div style={{ width: "18%", display: "flex" }}>
                          <div
                            className="visitorEntryInputContainer"
                            style={{ width: "78%", marginRight: "2%" }}
                          >
                            <div>
                              <label className="visitorInfoLabelContainer">
                                DOB
                              </label>
                            </div>
                            {/* <Controller
                              control={control}
                              name={`userForm.${index}.dob`}
                              render={({ field }) => (
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  className="visiterInfoCss rounded-start"
                                  showYearDropdown
                                  selected={field.value}
                                  onChange={(date) => {
                                    field.onChange(date);
                                    calculateAge(date,index);
                                  }}
                                />
                              )}
                            /> */}
                            <Controller
                              control={control}
                              name={`userForm.${index}.dob`}
                              render={({ field }) => (
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  className="visiterInfoCss rounded-start"
                                  showYearDropdown
                                  scrollableYearDropdown
                                  yearDropdownItemNumber={41} // Includes the current year + 40 previous years
                                  selected={field.value}
                                  maxDate={new Date()} // Restrict to current date and earlier
                                  minDate={subYears(new Date(), 40)} // Restrict to 40 years before the current date
                                  onChange={(date) => {
                                    field.onChange(date);
                                    calculateAge(date, index);
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div
                            className="visitorEntryInputContainer"
                            style={{ width: "20%" }}
                          >
                            <div>
                              <label className="visitorInfoLabelContainer">
                                Age
                              </label>
                            </div>
                            <Controller
                              control={control}
                              name={`userForm.${index}.age`}
                              render={(field) => (
                                <InputField
                                  {...field}
                                  visiterInfoCss="visiterInfoCss"
                                  type="text"
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Gender
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.gender`}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <select
                                value={value}
                                className="visiterInfoCss py-2"
                                onChange={onChange}
                                onBlur={onBlur} // Added onBlur for better form validation handling
                                ref={ref} // Make sure the ref is passed down for proper field tracking
                              >
                                <option value=""> </option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                {/* <option value="Other">Other</option> */}
                              </select>
                            )}
                          />
                        </div>
                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Religion
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.religion`}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <select
                                value={value}
                                className="visiterInfoCss py-2"
                                onChange={onChange}
                                onBlur={onBlur} // Added onBlur for better form validation handling
                                ref={ref} // Make sure the ref is passed down for proper field tracking
                              >
                                <option value=""> </option>
                                <option value="Christian">Christian</option>
                                <option value="Muslim">Muslim</option>
                                <option value="Hindu">Hindu</option>
                                <option value="Other">Other</option>
                              </select>
                            )}
                          />
                        </div>
                        {userForm[index]?.religion === "Other" && (
                          <div className="visitorEntryInputContainer">
                            <div>
                              <label className="visitorInfoLabelContainer">
                                Other Religion
                              </label>
                            </div>
                            <Controller
                              control={control}
                              name={`userForm.${index}.otherReligion`}
                              render={(field) => (
                                <InputField
                                  {...field}
                                  visiterInfoCss="visiterInfoCss"
                                  type="text"
                                />
                              )}
                            />
                          </div>
                        )}
                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Caste
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.caste`}
                            render={(field) => (
                              <InputField
                                {...field}
                                visiterInfoCss="visiterInfoCss"
                                type="text"
                              />
                            )}
                          />
                        </div>
                        <div className="visitorInfoMobile">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Mobile Number
                            </label>
                          </div>
                          <Controller
                            key={
                              alsoWhatApp
                                ? "whatsapp-synced"
                                : "whatsapp-independent"
                            }
                            control={control}
                            name={`userForm.${index}.mobileNumber`}
                            rules={{
                              minLength: {
                                value: 10,
                                message: "Mobile number must be 10 digits",
                              },
                              maxLength: {
                                value: 10,
                                message: "Mobile number must be 10 digits",
                              },
                              pattern: {
                                value: /^[0-9]{10}$/,
                                message:
                                  "Please enter a valid 10-digit mobile number",
                              },
                            }}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <>
                                <PinInput
                                  length={10}
                                  initialValue={value || ""}
                                  onChange={(val) => {
                                    onChange(val);

                                    if (alsoWhatApp && val?.length === 10) {
                                      setValue("whatsapp", val);
                                    }
                                  }}
                                  type="numeric"
                                  style={{ width: "100%", borderRadius: "5px" }}
                                  inputStyle={{
                                    border: "1px solid gray",
                                    backgroundColor: "#fff",
                                    height: "40px",
                                    width: "10%",
                                    margin: "0px",
                                    borderRadius: "5px",
                                  }}
                                  autoSelect
                                  onComplete={(value, index) => {
                                    console.log("onComplete", value);
                                    setMobileNumberData(value);
                                  }}
                                />
                                {error && (
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {error.message}
                                  </span>
                                )}
                              </>
                            )}
                          />
                        </div>
                        <div className="visitorInfoMobile">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Alternative Number
                            </label>
                          </div>
                          <Controller
                            key={
                              alsoWhatApp
                                ? "whatsapp-synced"
                                : "whatsapp-independent"
                            }
                            control={control}
                            name={`userForm.${index}.alternativeNumber`}
                            rules={{
                              minLength: {
                                value: 10,
                                message: "Mobile number must be 10 digits", // Error message if length is less than 10
                              },
                              maxLength: {
                                value: 10,
                                message: "Mobile number must be 10 digits", // Error message if length is more than 10
                              },
                              pattern: {
                                value: /^[0-9]{10}$/, // Ensure the input is numeric and exactly 10 digits
                                message:
                                  "Please enter a valid 10-digit mobile number", // Custom error message
                              },
                            }}
                            render={({
                              field: { onChange, onBlur, value, ref },
                              fieldState: { error },
                            }) => (
                              <>
                                <PinInput
                                  length={10}
                                  initialValue={value || ""}
                                  onChange={onChange}
                                  type="numeric"
                                  style={{ width: "100%", borderRadius: "5px" }}
                                  inputStyle={{
                                    border: "1px solid gray",
                                    backgroundColor: "#fff",
                                    height: "40px",
                                    width: "10%",
                                    margin: "0px",
                                    borderRadius: "5px",
                                  }}
                                  autoSelect={true}
                                />

                                {error && (
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {error?.message}{" "}
                                  </span>
                                )}
                              </>
                            )}
                          />
                        </div>
                        <div className="visitorInfoMobile">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              WhatsApp Number
                            </label>
                          </div>
                          <Controller
                            key={
                              alsoWhatApp
                                ? "whatsapp-synced"
                                : "whatsapp-independent"
                            } // Unique key for re-rendering
                            control={control}
                            name={`userForm.${index}.whatsapp`}
                            rules={{
                              minLength: {
                                value: 10,
                                message: "Mobile number must be 10 digits", // Error message when length is less than 10
                              },
                              maxLength: {
                                value: 10,
                                message: "Mobile number must be 10 digits", // Error message when length is more than 10
                              },
                              pattern: {
                                value: /^[0-9]{10}$/,
                                message:
                                  "Please enter a valid 10-digit mobile number", // Ensure it's numeric
                              },
                            }}
                            render={({ field: { onChange, value } }) => (
                              <PinInput
                                length={10}
                                initialValue={value}
                                value={value || ""}
                                onChange={(val) => onChange(val)}
                                type="numeric"
                                style={{ width: "100%", borderRadius: "5px" }}
                                inputStyle={{
                                  border: "1px solid gray",
                                  backgroundColor: "#fff",
                                  height: "40px",
                                  width: "10%",
                                  margin: "0px",
                                  borderRadius: "5px",
                                }}
                                autoSelect
                              />
                            )}
                          />
                          <div
                            style={{ fontSize: "12px", cursor: "pointer" }}
                            className="d-flex pt-1 justify-content-end"
                          >
                            <label className="d-flex gap-1 align-items-center">
                              <input
                                onChange={(e) => {
                                  mobileNumberCheckBox(e, index);
                                  // mobileCheckBox(e);
                                }}
                                type="checkbox"
                                style={{ width: "15px", height: "15px" }}
                              />
                              Also Same As Above
                            </label>
                          </div>
                        </div>
                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              E Mail
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.email`}
                            render={(field) => (
                              <InputField
                                {...field}
                                visiterInfoCss="visiterInfoCss"
                                type="email"
                              />
                            )}
                          />
                        </div>

                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Category
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.category`}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <select
                                className="visiterInfoCss"
                                onChange={onChange}
                                value={value}
                              >
                                <option value=" "> </option>
                                <option value="Visitor">Visitor</option>
                                <option value="Client">Client</option>
                                <option value="Perspective">paspective</option>
                                <option value="Business">Business</option>
                                <option value="Interview">Interview</option>

                                <option value="Other">Other</option>
                              </select>
                            )}
                          />
                        </div>
                        {userForm[index]?.category === "Other" && (
                          <div className="visitorEntryInputContainer">
                            <div>
                              <label className="visitorInfoLabelContainer">
                                other Category
                              </label>
                            </div>
                            <Controller
                              control={control}
                              name={`userForm.${index}.otherCategory`}
                              render={(field) => (
                                <InputField
                                  {...field}
                                  visiterInfoCss="visiterInfoCss"
                                  type="text"
                                />
                              )}
                            />
                          </div>
                        )}
                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Reference
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.sourceForm`}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <select
                                value={value}
                                className="visiterInfoCss"
                                onChange={onChange}
                              >
                                <option value=" "> </option>
                                <option value="JustDial">Just Dial</option>
                                <option value="YouTube">YouTube</option>
                                <option value="Google">Google</option>
                                <option value="Advertisement">
                                  Advertisement
                                </option>
                                <option value="SocialMedia">
                                  Social Media
                                </option>
                                <option value="Referred">Referred By</option>
                                <option value="Other">Other</option>
                              </select>
                            )}
                          />
                        </div>
                        {userForm[index]?.sourceForm === "Other" && (
                          <div className="visitorEntryInputContainer">
                            <div>
                              <label className="visitorInfoLabelContainer">
                                Other Reference
                              </label>
                            </div>
                            <Controller
                              control={control}
                              name={`userForm.${index}.otherSourceForm`}
                              render={(field) => (
                                <InputField
                                  {...field}
                                  visiterInfoCss="visiterInfoCss"
                                  type="text"
                                />
                              )}
                            />
                          </div>
                        )}
                        {userForm[index]?.sourceForm === "SocialMedia" && (
                          <div className="visitorEntryInputContainer">
                            <div>
                              <label className="visitorInfoLabelContainer">
                                Social Media Name
                              </label>
                            </div>
                            <Controller
                              control={control}
                              name={`userForm.${index}.otherSourceForm`}
                              render={(field) => (
                                <InputField
                                  {...field}
                                  visiterInfoCss="visiterInfoCss"
                                  type="text"
                                />
                              )}
                            />
                          </div>
                        )}
                        {userForm[index]?.sourceForm === "Referred" && (
                          <div className="visitorEntryInputContainer">
                            <div>
                              <label className="visitorInfoLabelContainer">
                                Reference By
                              </label>
                            </div>
                            <Controller
                              control={control}
                              name={`userForm.${index}.otherSourceForm`}
                              render={(field) => (
                                <InputField
                                  {...field}
                                  visiterInfoCss="visiterInfoCss"
                                  type="text"
                                />
                              )}
                            />
                          </div>
                        )}

                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Date of visit
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.dateOfVisit`}
                            render={({ field }) => (
                              <DatePicker
                                dateFormat="dd/MM/yyyy eeee"
                                className="visiterInfoCss"
                                selected={field.value}
                                onChange={(date) => field.onChange(date)}
                              />
                            )}
                          />
                        </div>
                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Date of Entry
                            </label>
                          </div>

                          <Controller
                            control={control}
                            name={`userForm.${index}.dateOfAppointment`}
                            defaultValue={moment().toDate()}
                            render={({ field }) => (
                              <DatePicker
                                dateFormat="dd/MM/yyyy eeee" // Format the date to display (e.g., Mon 06/11/2024)
                                className="visiterInfoCss"
                                readOnly
                                selected={
                                  field.value
                                    ? moment(field.value).toDate()
                                    : null
                                } // Convert moment to native Date
                                onChange={(date) =>
                                  field.onChange(moment(date))
                                } // Convert selected date to moment
                                minDate={moment().toDate()}
                                maxDate={moment().toDate()}
                                disabled={false}
                              />
                            )}
                          />
                        </div>

                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Status
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.status`}
                            defaultValue="Active"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <select
                                className="visiterInfoCss"
                                value={value}
                                onChange={onChange}
                              >
                                <option value=""></option>
                                <option value="Active">Active</option>
                                <option value="In Active"> In Active</option>
                              </select>
                            )}
                          />
                        </div>
                        <div className="d-flex" style={{ width: "18%" }}>
                          <div
                            className="visitorEntryInputContainer"
                            style={{ width: "50%" }}
                          >
                            <div>
                              <label className="visitorInfoLabelContainer">
                                In Time
                              </label>
                            </div>
                            <Controller
                              control={control}
                              name={`userForm.${index}.inTime`}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="time"
                                  className="visiterInfoCss"
                                />
                              )}
                            />
                          </div>
                          <div
                            className="visitorEntryInputContainer"
                            style={{ width: "50%" }}
                          >
                            <div>
                              <label className="visitorInfoLabelContainer">
                                Out Time
                              </label>
                            </div>
                            <Controller
                              control={control}
                              name={`userForm.${index}.outTime`}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="time"
                                  className="visiterInfoCss"
                                  // Set the min value dynamically based on In Time
                                  min={inTime || ""}
                                />
                              )}
                            />
                          </div>
                        </div>

                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Purpose of visit
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.purposeOfVisit`}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <textarea
                                ref={ref}
                                style={{ height: "40px", width: "100%" }}
                                value={value}
                                className="visiterInfoCss  py-2"
                                onFocus={() =>
                                  handleFocus(
                                    `userForm.${index}.purposeOfVisit`,
                                    "Purpose of visit",
                                    value
                                  )
                                }
                                onChange={(e) => {
                                  onChange(e.target.value);
                                }}
                                onBlur={onBlur}
                                disabled={isModalOpen}
                              ></textarea>
                            )}
                          />
                        </div>
                        <div
                          className="visitorEntryInputContainer"
                          // style={{ width: "18%", height: "50px" }}
                        >
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Additional Particulars
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.additionalStatus`}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <textarea
                                ref={ref}
                                style={{ height: "40px", width: "100%" }}
                                value={value}
                                className="visiterInfoCss  py-2"
                                onFocus={() =>
                                  handleFocus(
                                    `userForm.${index}.additionalStatus`,
                                    "Additional Particulars",
                                    value
                                  )
                                }
                                onChange={(e) => {
                                  onChange(e.target.value);
                                }}
                                onBlur={onBlur}
                                disabled={isModalOpen}
                              ></textarea>
                            )}
                          />
                        </div>

                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">Note 1</label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.visitorNote1`}
                            render={(field) => (
                              <InputField
                                visiterInfoCss="visiterInfoCss"
                                {...field}
                                type="text"
                              />
                            )}
                          />
                        </div>
                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">Note 2</label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.visitorNote2`}
                            render={(field) => (
                              <InputField
                                visiterInfoCss="visiterInfoCss"
                                {...field}
                                type="text"
                              />
                            )}
                          />
                        </div>

                        <div className="d-flex" style={{ width: "18%" }}>
                          <div
                            className="visitorInfoShowBtn fs-6 px-2 text-center"
                            title={
                              userForm[index]?.temporaryAddress ||
                              "No address available"
                            }
                            style={
                              userForm[index]?.temporaryAddress
                                ? {
                                    backgroundColor: "green",
                                    color: "white",
                                    cursor: "pointer",
                                    width: "100%",
                                    height: "40px",
                                    alignContent: "center",
                                  }
                                : {
                                    backgroundColor: "red",
                                    color: "white",
                                    cursor: "pointer",
                                    width: "100%",
                                    height: "40px",
                                    alignContent: "center",
                                  }
                            }
                            onClick={() => {
                              setShowPresentAddAppend(index);
                            }}
                          >
                            Present Address
                          </div>
                        </div>
                        <div className="d-flex" style={{ width: "18%" }}>
                          <div
                            className="visitorInfoShowBtn fs-6 px-2 text-center"
                            title={
                              userForm[index]?.officeAddress ||
                              "No address available"
                            }
                            style={
                              userForm[index]?.officeAddress ||
                              userForm[index]?.officeAddNo ||
                              userForm[index]?.officeAddEmail ||
                              userForm[index]?.officeAdditionalDetails
                                ? {
                                    backgroundColor: "green",
                                    color: "white",
                                    cursor: "pointer",
                                    width: "100%",
                                    height: "40px",
                                    alignContent: "center",
                                  }
                                : {
                                    backgroundColor: "red",
                                    color: "white",
                                    cursor: "pointer",
                                    width: "100%",
                                    height: "40px",
                                    alignContent: "center",
                                  }
                            }
                            onClick={() => {
                              setOfficeAppend(index);
                            }}
                          >
                            Office Address
                          </div>
                        </div>
                        <div className="d-flex" style={{ width: "18%" }}>
                          <div
                            className="visitorInfoShowBtn fs-6 px-2 text-center"
                            title={
                              userForm[index]?.permanentAddress ||
                              "No address available"
                            }
                            style={
                              userForm[index]?.permanentAddress ||
                              userForm[index]?.permanentAddNo ||
                              userForm[index]?.permanentAddEmail ||
                              userForm[index]?.permanentAdditionalDetails
                                ? {
                                    backgroundColor: "green",
                                    color: "white",
                                    cursor: "pointer",
                                    width: "100%",
                                    height: "40px",
                                    alignContent: "center",
                                  }
                                : {
                                    backgroundColor: "red",
                                    color: "white",
                                    cursor: "pointer",
                                    width: "100%",
                                    height: "40px",
                                    alignContent: "center",
                                  }
                            }
                            onClick={() => {
                              // setShowPermanentAddAppend(index);
                              setShowPermanentAppend(index);
                            }}
                          >
                            Permanent Address
                          </div>
                        </div>
                        <div
                          className="d-flex justify-content-start mt-2 pt-1 ps-2"
                          style={{ width: "18%" }}
                        >
                          <div
                            style={{
                              color: "white",
                              cursor: "pointer",
                              width: "100%",
                              height: "42px",
                              alignContent: "center",
                            }}
                            onClick={() => {
                              remove(index);
                            }}
                            className="visitorInfoSaveBtn px-3 text-center"
                          >
                            Delete Detail
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="d-flex gap-4 pe-3 mt-2 justify-content-between text-center ">
                <div>
                  <div
                    style={{ width: "210px" }}
                    id="field"
                    onClick={() => {
                      // window.scrollTo(0,1000)

                      append({}, { shouldFocus: false });
                    }}
                    className="visitorInfoSaveBtn p-2 "
                  >
                    Additional Visitor
                  </div>
                </div>
                <div>
                  <button
                    onClick={() => {
                      // console.log("hello");
                    }}
                    type="submit"
                    id="aboutUs"
                    className="visitorInfoSaveBtn p-2 px-4"
                    style={{ backgroundColor: "green", width: "210px" }}
                  >
                    {isLoading ? (
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
      <Alert
        open={postAlert}
        setPutAlert={setPutAlert}
        postAlert={postAlert}
        state={state}
        upData={upData}
        putAlert={putAlert}
        mutate={mutate}
        navigates="/"
        id={state?.id}
        add={true}
        onCloses={setPostAlert}
        // setPutAlert={setPutAlert}
      />
      {isModalOpen && currentField && (
        <Modal
          show={isModalOpen}
          onClose={() => setModalOpen(false)}
          onSave={handleSave}
          value={currentField?.value} // Pass current value to the modal
          currentField={currentField}
        />
      )}
      <AddressModal isOpen={showPresentAdd !== null}>
        <div className="mb-3 text-end">
          <img
            title="Remove details and close"
            onClick={() => {
              setShowPresentAdd(null);
              setValue("temporaryAddress", "");
            }}
            src={wrongIcon}
            style={{
              // backgroundColor: "black",
              height: "30px",
              borderRadius: "8px",
            }}
            alt=""
          />
        </div>
        <div className="d-flex gap-5">
          <div style={{ width: "100%", display: "" }}>
            <header
              className="fs-5 fw-bold text-center m-0 visitorInfoShowBtn py-2"
              style={{ color: "#fff" }}
            >
              Present Address
            </header>
            <div
              className="visitorEntryInputContainer my-2"
              style={{ width: "100%", height: "200px" }}
            >
              <div>
                <label className="visitorInfoLabelContainer">Address</label>
              </div>

              <Controller
                control={control}
                name="temporaryAddress"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    style={{ minHeight: "100px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            onClick={() => {
              setShowPresentAdd(null);
            }}
            className="visitorInfoSaveBtn fs-3 px-3"
          >
            Save
          </button>
        </div>
      </AddressModal>

      <AddressModal isOpen={showOfficeAdd !== null}>
        <div className="mb-2 text-end">
          <img
            title="Remove details and close"
            onClick={() => {
              setShowOfficeAdd(null);
              setValue("officeAddress", "");
              setValue("officeAddNo", "");
              setValue("officeAddEmail", "");
              setValue("officeAdditionalDetails", "");
            }}
            src={wrongIcon}
            style={{
              // backgroundColor: "black",
              height: "30px",
              borderRadius: "8px",
              // marginBottom: "20px",
            }}
            alt=""
          />
        </div>
        <div className="d-flex gap-5">
          <div className="" style={{ width: "100%", display: "" }}>
            <header
              className="fs-5 fw-bold text-center m-0 visitorInfoShowBtn py-2"
              style={{ color: "#fff" }}
            >
              Office Address Details
            </header>
            <div
              className="visitorEntryInputContainer my-2"
              style={{ width: "100%" }}
            >
              <div>
                <label className="visitorInfoLabelContainer">Address</label>
              </div>

              <Controller
                control={control}
                name="officeAddress"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    // style={{ minHeight: "100px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>

            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer">Mobile No</label>
              </div>
              <Controller
                control={control}
                name="officeAddNo"
                render={(field) => (
                  <InputField
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="number"
                  />
                )}
              />
            </div>

            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer">Mail id</label>
              </div>
              <Controller
                control={control}
                name="officeAddEmail"
                render={(field) => (
                  <InputField
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="email"
                  />
                )}
              />
            </div>

            <div>
              <div>
                <label className="visitorInfoLabelContainer">
                  Additional Details
                </label>
              </div>
              <Controller
                control={control}
                name="officeAdditionalDetails"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    style={{ minHeight: "100px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            onClick={() => {
              setShowOfficeAdd(null);
            }}
            className="visitorInfoSaveBtn fs-3 px-3"
          >
            Save
          </button>
        </div>
      </AddressModal>

      <AddressModal isOpen={showPermanentAddress !== null}>
        <div className="mb-3 text-end">
          <img
            title="Remove details and close"
            onClick={() => {
              setShowPermanentAddress(null);
              setValue("permanentAddress", "");
              setValue("permanentAddNo", "");
              setValue("permanentAddEmail", "");
              setValue("permanentAdditionalDetails", "");
            }}
            src={wrongIcon}
            style={{
              // backgroundColor: "black",
              height: "30px",
              borderRadius: "8px",
            }}
            alt=""
          />
        </div>
        <div className="d-flex gap-5">
          <div style={{ width: "100%", display: "" }}>
            <header
              className="fs-5 fw-bold text-center m-0 visitorInfoShowBtn py-2"
              style={{ color: "#fff" }}
            >
              Permanent Address Details
            </header>
            <div
              className="visitorEntryInputContainer my-2"
              style={{ width: "100%" }}
            >
              <div>
                <label className="visitorInfoLabelContainer">Address</label>
              </div>

              <Controller
                control={control}
                name="permanentAddress"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    // style={{ minHeight: "100px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
            <div>
              <div>
                <label className="visitorInfoLabelContainer ">Mobile No</label>
              </div>
              <Controller
                control={control}
                name="permanentAddNo"
                render={(field) => (
                  <InputField
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="number"
                  />
                )}
              />
            </div>
            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer ">Mail id</label>
              </div>
              <Controller
                control={control}
                name="permanentAddEmail"
                render={(field) => (
                  <InputField
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="email"
                  />
                )}
              />
            </div>
            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer">
                  Additional Details
                </label>
              </div>
              <Controller
                control={control}
                name="permanentAdditionalDetails"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    style={{ minHeight: "100px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            onClick={() => {
              setShowPermanentAddress(null);
            }}
            className="visitorInfoSaveBtn fs-3 px-3"
          >
            Save
          </button>
        </div>
      </AddressModal>

      <AddressModal isOpen={showPresentAddAppend !== null}>
        <div className="mb-3 text-end">
          <img
            title="Remove details and close"
            onClick={() => {
              setShowPresentAddAppend(null);
              setValue(`userForm.${showPresentAddAppend}.temporaryAddress`, "");
            }}
            src={wrongIcon}
            style={{
              // backgroundColor: "black",
              height: "30px",
              borderRadius: "8px",
            }}
            alt=""
          />
        </div>
        <div className="d-flex gap-5">
          <div style={{ width: "100%", display: "" }}>
            <header
              className="fs-5 fw-bold text-center m-0 visitorInfoShowBtn py-2"
              style={{ color: "#fff" }}
            >
              Present Address
            </header>
            <div
              className="visitorEntryInputContainer my-2"
              style={{ width: "100%", height: "200px" }}
            >
              <div>
                <label className="visitorInfoLabelContainer">Address</label>
              </div>

              <Controller
                control={control}
                name={`userForm.${showPresentAddAppend}.temporaryAddress`}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    style={{ minHeight: "100px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            onClick={() => {
              setShowPresentAddAppend(null);
            }}
            className="visitorInfoSaveBtn fs-3 px-3"
          >
            Save
          </button>
        </div>
      </AddressModal>

      <AddressModal isOpen={showOfficeAppend !== null}>
        <div className=" text-end mb-2">
          <img
            title="Remove details and close"
            onClick={() => {
              setOfficeAppend(null);
              setValue(`userForm.${showPresentAddAppend}.officeAddress`, "");
              setValue(`userForm.${showPresentAddAppend}.officeAddNo`, "");
              setValue(`userForm.${showPresentAddAppend}.officeAddEmail`, "");
              setValue(
                `userForm.${showPresentAddAppend}.officeAdditionalDetails`,
                ""
              );
            }}
            src={wrongIcon}
            style={{
              // backgroundColor: "black",
              height: "30px",
              borderRadius: "8px",
            }}
            alt=""
          />
        </div>
        <div className="d-flex gap-5">
          <div className="" style={{ width: "100%", display: "" }}>
            <header
              className="fs-5 fw-bold text-center m-0 visitorInfoShowBtn py-2"
              style={{ color: "#fff" }}
            >
              Office Address Details
            </header>
            <div
              className="visitorEntryInputContainer my-2"
              style={{ width: "100%" }}
            >
              <div>
                <label className="visitorInfoLabelContainer">Address</label>
              </div>

              <Controller
                control={control}
                name={`userForm.${showOfficeAppend}.officeAddress`}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    // style={{ minHeight: "100px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>

            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer">Mobile No</label>
              </div>
              <Controller
                control={control}
                name={`userForm.${showOfficeAppend}.officeAddNo`}
                render={(field) => (
                  <InputField
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="number"
                  />
                )}
              />
            </div>

            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer">Mail id</label>
              </div>
              <Controller
                control={control}
                name={`userForm.${showOfficeAppend}.officeAddEmail`}
                render={(field) => (
                  <InputField
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="email"
                  />
                )}
              />
            </div>

            <div>
              <div>
                <label className="visitorInfoLabelContainer">
                  Additional Details
                </label>
              </div>
              <Controller
                control={control}
                name={`userForm.${showOfficeAppend}.officeAdditionalDetails`}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    style={{ minHeight: "100px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            onClick={() => {
              setOfficeAppend(null);
            }}
            className="visitorInfoSaveBtn fs-3 px-3"
          >
            Save
          </button>
        </div>
      </AddressModal>

      <AddressModal isOpen={showPermanentAppend !== null}>
        <div className=" text-end mb-2">
          <img
            title="Remove details and close"
            onClick={() => {
              setShowPermanentAppend(null);
              setValue(`userForm.${showPermanentAppend}.permanentAddress`, "");
              setValue(`userForm.${showPermanentAppend}.permanentAddNo`, "");
              setValue(`userForm.${showPermanentAppend}.permanentAddEmail`, "");
              setValue(
                `userForm.${showPermanentAppend}.permanentAdditionalDetails`,
                ""
              );
            }}
            src={wrongIcon}
            style={{
              // backgroundColor: "black",
              height: "30px",
              borderRadius: "8px",
            }}
            alt=""
          />
        </div>
        <div style={{ width: "100%", display: "" }}>
          <header
            className="fs-5 fw-bold text-center m-0 visitorInfoShowBtn py-2"
            style={{ color: "#fff" }}
          >
            Permanent Address Details
          </header>
          <div
            className="visitorEntryInputContainer my-2"
            style={{ width: "100%" }}
          >
            <div>
              <label className="visitorInfoLabelContainer">Address</label>
            </div>

            <Controller
              control={control}
              name={`userForm.${showPermanentAppend}.permanentAddress`}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <textarea
                  // style={{ minHeight: "100px" }}
                  value={value}
                  className="visitorAddCss py-2"
                  onChange={onChange}
                ></textarea>
              )}
            />
          </div>
          <div>
            <div>
              <label className="visitorInfoLabelContainer ">Mobile No</label>
            </div>
            <Controller
              control={control}
              name={`userForm.${showPermanentAppend}.permanentAddNo`}
              render={(field) => (
                <InputField
                  visiterInfoCss="visiterInfoCss"
                  {...field}
                  type="number"
                />
              )}
            />
          </div>
          <div className="my-2">
            <div>
              <label className="visitorInfoLabelContainer ">Mail id</label>
            </div>
            <Controller
              control={control}
              name={`userForm.${showPermanentAppend}.permanentAddEmail`}
              render={(field) => (
                <InputField
                  visiterInfoCss="visiterInfoCss"
                  {...field}
                  type="email"
                />
              )}
            />
          </div>
          <div className="my-2">
            <div>
              <label className="visitorInfoLabelContainer">
                Additional Details
              </label>
            </div>
            <Controller
              control={control}
              name={`userForm.${showPermanentAppend}.permanentAdditionalDetails`}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <textarea
                  style={{ minHeight: "100px" }}
                  value={value}
                  className="visitorAddCss py-2"
                  onChange={onChange}
                ></textarea>
              )}
            />
          </div>
        </div>
        <div className="text-center">
          <button
            onClick={() => {
              setShowPermanentAppend(null);
            }}
            className="visitorInfoSaveBtn fs-3 px-3"
          >
            Save
          </button>
        </div>
      </AddressModal>
    </div>
  );
}
