import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Images from "../asset";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Loading from "./Loading";
import moment from "moment/moment";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Alert from "./Alert";
const ClientListCom = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [dltId, setDltId] = useState();
  const [postAlert, setPostAlert] = useState();
  const [putAlert, setPutAlert] = useState();
  const [sortOrder, setSortOrder] = useState(null);
  const userData = sessionStorage.getItem("userdata");

  const clientData = async () => {
    return await axios.get(`https://api.2kvirtualworld.com/DADEV/visitors/all`);
  };

  const { data, isLoading, isError, refetch } = useQuery(
    "clientData",
    clientData
  );
  const deleteUser = async (id) => {
    return await axios.delete(
      `https://api.2kvirtualworld.com/DADEV/visitors/delete/${id}`
    );
  };

  // // Delete multiple users
  const deleteUsers = async (ids) => {
    return await Promise.all(
      ids?.map((id) => {
        return deleteUser(id);
      })
    );
  };
  let details = (data && data?.data) || [];

  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows?.includes(id);
      if (isSelected) {
        return prevSelectedRows?.filter((rowId) => rowId !== id);
      } else {
        return [...prevSelectedRows, id];
      }
    });
  };

  const mutation = useMutation({
    mutationFn: deleteUsers,
    onSuccess: () => {
      refetch();
      setPutAlert(false);
      setSelectedRows([]); // Clear selected rows
    },
    onError: (error) => {
      // console.error("Error deleting rows:", error);
      alert("Error deleting rows");
    },
  });
  const handleDelete = (id) => {
    setDltId(id);
    setPostAlert(true);
  };
  useEffect(() => {
    if (selectedRows.length !== 0 && putAlert) {
      mutation.mutate(selectedRows);
      setPostAlert(false);
      setPutAlert(false);
    } else if (putAlert && dltId) {
      deleteUser(dltId)
        .then(() => {
          refetch();
          setPostAlert(false);
          setPutAlert(false);
          setDltId(null);
        })
        .catch((error) => {
          alert("Error deleting row");
        });
    }
  }, [selectedRows, putAlert, dltId]);

  // Filter details based on search term
  const filteredDetails =
    details &&
    details?.filter((detail) => {
      if (!searchTerm) return true;
      const { caseNumber } = detail?.caseDetails || {};
      const { subjectName, subjectMobileNumber } = detail?.subjectDetails || {};
      const { mobileNumber, clientName, email, age, address } =
        detail?.clientInformation || {};
      const term = searchTerm?.toLowerCase();
      return (
        caseNumber?.toString().toLowerCase().startsWith(term) ||
        mobileNumber?.toString().toLowerCase().startsWith(term) ||
        clientName?.toLowerCase().startsWith(term) ||
        email?.toString().toLowerCase().startsWith(term) ||
        age?.toString().toLowerCase().startsWith(term) ||
        detail?.subjectDetails?.subjectName?.toLowerCase().startsWith(term) ||
        detail?.subjectDetails?.subjectMobileNumber
          ?.toString()
          .toLowerCase()
          .startsWith(term) ||
        detail?.subjectDetails?.subjectPermanentAddress
          ?.toString()
          .toLowerCase()
          .startsWith(term) ||
        address?.toString().toLowerCase().startsWith(term)
      );
    });

  const downloadExcel = () => {
    // Determine data to export based on filter
    const exportData =
      filteredDetails && filteredDetails?.length > 0
        ? filteredDetails
        : filteredDetails;

    // Prepare data for the table worksheet
    const tableDatas =
      exportData && exportData?.length > 0
        ? exportData?.map((data, index) => ({
            "S.NO": index + 1,
            // "FILE NO": data?.clientInformation?.clientName || "NULL",
            "  CLIENT NAME": data?.clientInformation?.clientName || "",
            "CLIENT AGE": data?.clientInformation?.age || "",
            "NAME OF PARENTS/GUARDIANS":
              data?.clientInformation?.guardianOrParent || "",
            "CLIENT MOBILE NUMBER":
              data?.clientInformation?.mobileNumber || "",
            "CLIENT EMAIL ID": data?.clientInformation?.email || "",
            "CLIENT ADDRESS": data?.clientInformation?.address || "",
            "SUBJECT NAME": data?.subjectDetails?.subjectName || "",
            "SUBJECT AGE": data?.clientInformation?.age || "",
            "SUBJECT MOBILE NO":
              data?.subjectDetails?.subjectMobileNumber || "",
            // "SUBJECT RELATION": data?.subjectDetails?.mobileNumber || "",
            "SUBJECT ADDRESS":
              data?.subjectDetails?.subjectPermanentAddress || "",
            "NATURE OF CASE(TITLE)":
              data?.caseDetails?.natureOfCareTitle || "",
            "NATURE OF CASE(DETAILS)":
              data?.caseDetails?.natureOfCareDetails || "",
            "NATURE OF CASE(REQUIREMENTS)":
              data?.caseDetails?.natureOfCareClientReq || "",
            "REFERENCE(JD/GS/OTHERS)":
              data?.referenceDetails?.reference || "",
            "NAME OF(IOs)": data?.referenceDetails?.nameOfLos || "",
            "DATE OF CLOSING": data?.caseDetails?.dateOfClosed || "",
            // "FILE NUMBER": data?.referenceDetails?.fileNumber || "",
            "REASON FOR CLOSURE":
              data?.referenceDetails?.reasonForClosure || "",
            CR: data?.referenceDetails?.confReport || "",
          }))
        : []; // Default to an empty array

    // Create table worksheet with headers
    const tableWorksheet = XLSX.utils.json_to_sheet(
      tableDatas?.length > 0 ? tableDatas : [{}],
      {
        skipHeader: false, // Ensure the header is included
      }
    );

    // If tableDatas is empty, add headers manually
    if (tableDatas?.length === 0) {
      XLSX.utils.sheet_add_aoa(tableWorksheet, [
        [
          "S.NO",
          // "FILE NO",
          "CLIENT NAME",
          "CLIENT AGE",
          "NAME OF PARENTS/GUARDIANS",
          "CLIENT MOBILE NUMBER",
          "CLIENT EMAIL ID",
          "CLIENT ADDRESS",
          "SUBJECT NAME",
          "SUBJECT AGE",
          "SUBJECT MOBILE NO",
          "SUBJECT ADDRESS",
          "NATURE OF CASE(TITLE)",
          "NATURE OF CASE(DETAILS)",
          "NATURE OF CASE(REQUIREMENTS)",
          "REFERENCE(JD/GS/OTHERS)",
          "NAME OF(IOs)",
          "DATE OF CLOSING",
          "FILE NUMBER",
          "REASON FOR CLOSURE",
        ],
      ]);
    }

    // Create a new workbook and add the worksheets
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, tableWorksheet, "Table Data");

    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Save the Excel file
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "Client Information.xlsx");
  };

  const sortedData = useMemo(() => {
    if (!filteredDetails) return [];
    const sorted = [...filteredDetails];
    if (sortOrder === "atoz") {
      sorted?.sort((a, b) =>
        a?.clientInformation?.clientName
          ?.toLowerCase()
          .localeCompare(b?.clientInformation?.clientName?.toLowerCase())
      );
    } else if (sortOrder === "ztoa") {
      sorted?.sort((a, b) =>
        b?.clientInformation?.clientName
          ?.toLowerCase()
          .localeCompare(a?.clientInformation?.clientName?.toLowerCase())
      );
    }
    return sorted;
  }, [filteredDetails, sortOrder]);

  return (
    <>
      <div className="visitor-main-container px-1 m-0">
        <div className="row">
          <div className="col-4 pt-3 pb-2 d-flex align-items-center gap-1">
            <div
              style={{ width: "50px", height: "40px", cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              <img
                src={Images?.arrowBack}
                style={{ width: "100%", height: "100%" }}
                alt="back"
                title="back arrow"
              />
            </div>
            <h6 className="client-view-head pt-2">Client Information</h6>
          </div>
          <div className="col-8 d-flex align-items-center justify-content-end gap-2">
            <div className="search-block-client-view">
              <input
                type="search"
                className="table-search"
                value={searchTerm}
                placeholder="Search by caseFileNo, name, age, mobNo, emailID, place"
                title="Search by caseFileNo, name, age, mobNo, emailID, place"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="image_icon">
                <img
                  src={Images.searchIcon}
                  style={{ width: "100%", height: "100%" }}
                  alt="Search"
                />
              </div>
            </div>
            <button
              className="new-register-btn --client-register"
              onClick={() => {
                downloadExcel();
              }}
            >
              Download
            </button>
            <button
              className="new-register-btn --client-register"
              onClick={() => {
                navigate("/clientEntry");
              }}
            >
              New Client Register
            </button>
            {selectedRows == undefined || selectedRows?.length == 0 ? (
              <></>
            ) : (
              <>
                <button
                  className="delete-btn"
                  onClick={handleDelete}
                  // disabled={mutation.isLoading}
                >
                  <img
                  style={{height:"30px"}}
                    src={Images?.deleteIcon}
                    alt={"deleteIcon"}
                    // style={{ width: "100%", height: "100%" }}
                  />
                  {/* {mutation.isLoading ? "Deleting..." : "DELETE"} */}
                </button>
              </>
            )}
          </div>
        </div>
        <div className="register-table-block">
          <TableContainer className="product_table_container_sticky">
            <Table>
              <TableHead>
                <TableRow>
              <TableCell className="product_table_heading" style={{width:"50px"}}>
                    S.No
                  </TableCell>
                  {userData == "admin" ? (
                    <></>
                  ) : (
                    <>
                      <TableCell className="product_table_heading">
                        Edit/Delete
                      </TableCell>
                    </>
                  )}
                  <TableCell className="product_table_heading">
                    Case File No
                  </TableCell>
                  {/* <TableCell className="product_table_heading">
                    Date Of Registration
                  </TableCell> */}
                  <TableCell className="product_table_heading">
                    Client's Name & Age with Credentials
                    <div className="d-flex gap-2 align-items-center">
                      <button
                        className="filter-button"
                        onClick={() => setSortOrder("atoz")}
                        style={{
                          fontSize: "5px",
                          textAlign: "center",
                        }}
                      >
                        <img
                          src={Images?.arrowdown}
                          alt="no-image"
                          style={{ width: "100%", height: "100%" }}
                        />
                      </button>
                      <button
                        className="filter-button"
                        onClick={() => setSortOrder("ztoa")}
                        style={{
                          fontSize: "5px",
                          textAlign: "center",
                        }}
                      >
                        <img
                          src={Images?.arrowup}
                          alt="no-images"
                          style={{ width: "100%", height: "100%" }}
                        />
                      </button>
                    </div>
                  </TableCell>
                  <TableCell className="product_table_heading">
                    Name of Parents/Guardian/Spouse/Children/Others & Contact
                    No/
                  </TableCell>
                  <TableCell className="product_table_heading">
                    Client's Mob No
                  </TableCell>
                  <TableCell className="product_table_heading">
                    Email Id, if any
                  </TableCell>
                  <TableCell className="product_table_heading">
                    Client's Address & Add1 Info
                  </TableCell>
                  <TableCell className="product_table_heading">
                    Subject's Name
                  </TableCell>
                  <TableCell className="product_table_heading">
                    Relationship between Subject & Client
                  </TableCell>
                  <TableCell className="product_table_heading">
                    Subject's Mobile No
                  </TableCell>
                  <TableCell className="product_table_heading">
                    Name Of Parents/Guardian/Spouse/Others & Mob No : other Add1
                    info:If any
                  </TableCell>
                  <TableCell className="product_table_heading">
                    Subject's Res.Address
                  </TableCell>
                  <TableCell className="product_table_heading">
                    NATURE OF CASE(Title)
                  </TableCell>
                  <TableCell className="product_table_heading">
                    NATURE OF CASE(Details)
                  </TableCell>
                  <TableCell className="product_table_heading">
                    NATURE OF CASE(Client's Requirements)
                  </TableCell>
                  <TableCell className="product_table_heading">
                    Reference(JD/GS/Others)
                  </TableCell>
                  <TableCell className="product_table_heading">
                    Name of (IOs)
                  </TableCell>
                  <TableCell className="product_table_heading">
                    Date Of Closing
                  </TableCell>
                  {/* <TableCell className="product_table_heading">
                    File No
                  </TableCell> */}
                  <TableCell className="product_table_heading">
                    Reason For Closure
                  </TableCell>
                  <TableCell className="product_table_heading">
                    Conferencial Report
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <Loading />
                ) : sortedData && sortedData?.length > 0 ? (
                  sortedData?.map((item, i) => (
                    <TableRow key={i}>
                    <TableCell className="product_table_data">
                    {i+1}
                  </TableCell>
                      {userData == "admin" ? (
                        <></>
                      ) : (
                        <>
                          <TableCell className="product_table_data">
                            <div className="d-flex align-items-start justify-content-start gap-1">
                              <div className="d-flex align-items-center justify-content-center gap-2">
                                <input
                                  type="checkbox"
                                  checked={selectedRows?.includes(item?.id)}
                                  title="Select Row"
                                  onChange={() =>
                                    handleCheckboxChange(item?.id)
                                  }
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    cursor: "pointer",
                                  }}
                                />

                                <div
                                  style={{
                                    width: "25px",
                                    height: "30px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src={Images?.pencilIcon}
                                    alt="edit-icon"
                                    title="Edit Row"
                                    style={{ width: "100%", height: "100%" }}
                                    onClick={() => {
                                      navigate("/clientEntry", {
                                        state: item,
                                      });
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    width: "27px",
                                    height: "27px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src={Images?.deleteIcon}
                                    alt="edit-icon"
                                    style={{ width: "100%", height: "100%" }}
                                    onClick={() => handleDelete(item?.id)}
                                    title="Delete Row"
                                  />
                                </div>
                              </div>
                            </div>
                          </TableCell>
                        </>
                      )}
                      <TableCell className="product_table_data">
                        {(item && item?.caseDetails?.caseNumber) || "-"}
                      </TableCell>
                      {/* <TableCell className="product_table_data">
                        {(item && item?.caseDetails?.date) || "-"}
                      </TableCell> */}
                      <TableCell className="product_table_data">
                        {`${item && item?.clientInformation?.clientName} & ${
                          item?.clientInformation?.age
                        } ` || "-"}
                      </TableCell>
                      <TableCell className="product_table_data">
                        {(item && item?.clientInformation?.guardianOrParent) ||
                          "-"}
                      </TableCell>
                      <TableCell className="product_table_data">
                        {(item && item?.clientInformation?.mobileNumber) || "-"}
                      </TableCell>
                      <TableCell className="product_table_data">
                        {(item && item?.clientInformation?.email) || "-"}
                      </TableCell>
                      <TableCell className="product_table_data">
                        {(item && item?.clientInformation?.address) || "-"}
                      </TableCell>
                      <TableCell className="product_table_data">
                        {`${item && item?.subjectDetails?.subjectName}` || "-"}
                      </TableCell>
                      <TableCell className="product_table_data">
                        {(item && item?.clientInformation?.guardianOrParent) ||
                          "-"}
                      </TableCell>
                      <TableCell className="product_table_data">
                        {(item && item?.subjectDetails?.subjectMobileNumber) ||
                          "-"}
                      </TableCell>
                      <TableCell className="product_table_data">
                        {(item && item?.clientInformation?.guardianOrParent) ||
                          "-"}
                      </TableCell>
                      <TableCell className="product_table_data">
                        {(item &&
                          item?.subjectDetails?.subjectPermanentAddress) ||
                          "-"}
                      </TableCell>
                      <TableCell className="product_table_data">
                        {(item && item?.caseDetails?.natureOfCareTitle) || "-"}
                      </TableCell>
                      <TableCell className="product_table_data">
                        {(item && item?.caseDetails?.natureOfCareDetails) ||
                          "-"}
                      </TableCell>
                      <TableCell className="product_table_data">
                        {(item && item?.caseDetails?.natureOfCareClientReq) ||
                          "-"}
                      </TableCell>
                      <TableCell className="product_table_data">
                        {(item && item?.referenceDetails?.reference) || "-"}
                      </TableCell>
                      <TableCell className="product_table_data">
                        {(item && item?.referenceDetails?.nameOfLos) || "-"}
                      </TableCell>
                      <TableCell className="product_table_data">
                        {(item && item?.caseDetails?.dateOfClosed) || "-"}
                      </TableCell>
                      {/* <TableCell className="product_table_data">
                        {(item && item?.referenceDetails?.fileNumber) || "-"}
                      </TableCell> */}
                      <TableCell className="product_table_data">
                        {(item && item?.referenceDetails?.reasonForClosure) ||
                          "-"}
                      </TableCell>
                      <TableCell className="product_table_data">
                        {(item && item?.referenceDetails?.confReport) || "-"}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={23}>No data available</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <Alert
        open={postAlert}
        setPutAlert={setPutAlert}
        // navigate = "/clientList"
        navigation = "/clientList"
        // postAlert={postAlert}
        // putAlert={putAlert}
        add={false}
        onCloses={setPostAlert}
        // setPutAlert={setPutAlert}
      />
    </>
  );
};

export default ClientListCom;
